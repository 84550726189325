import { computed, makeAutoObservable, observable } from "mobx";
import isEmpty from 'lodash.isempty';
import { message } from 'antd';

import rpgRequest from '../lib/rpgRequest.js';

const CRX_ID = 'iiegjioepjdlibhijdknnlphbphihbbe';

class AuthStore {
  @observable currentUser = null;
  @observable isLoading = true;

  constructor() {
    makeAutoObservable(this);

    this.port = null;

    this.initUser();
  }

  initUser = () => {
    rpgRequest('/api/current_user')
      .then((res) => {
        this.currentUser = res.data;
        this.isLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.isLoading = false;
      });
  }

  signIn = (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/sessions', 'post', data)
        .then((res) => {
          message.success('Sign in successfully.', 5);
          window.location.reload();
          resolve(res.data);
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  signOut = () => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/sessions', 'delete')
        .then((res) => {
          message.success('Sign out successfully.', 5);
          window.location.reload();
          resolve(res.data);
        })
        .catch((err) => {
          message.error('Sign out failed.', 5);
          reject(err);
        });
    });
  }

  registerUser = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/users', 'post', data)
        .then((res) => {
          this.currentUser = res.data;
          this.sendVerifyEmail(false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          message.error(err.response?.data?.message, 5);
          reject(err);
        });
    });
  }

  sendVerifyEmail = async (withNotify = true) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/send_verification_code')
        .then((resp) => {
          !!withNotify && message.success(`Sent an email to ${this.currentUser.email}`, 5);
          resolve(resp.data);
        })
        .catch((err) => {
          !!withNotify && message.error(err.response?.data?.message, 5);
          reject(err);
        });
    });
  }

  verifyEmailByCode = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/verify_code', 'post', data)
        .then((resp) => {
          this.currentUser.email_confirmed = true;
          resolve(resp.data);
        })
        .catch((err) => {
          console.error(err);
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  requestResetPassword = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/users/request_reset_password', 'post', data)
        .then((resp) => {
          message.success(`Sent code to ${data.email}`);
          resolve(data);
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  resetPassword = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/users/reset_password', 'post', data)
        .then((resp) => {
          message.success('Password has been reset, please login.');
          resolve();
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  updateUserProfile = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/user', 'put', data)
        .then((res) => {
          this.currentUser = res.data;
          message.success('You updated the profile info successfully.', 5);
          resolve();
        })
        .catch((err) => {
          message.error('Failed to update the profile info.', 5);
          reject(err);
        });
    });
  }

  getCompanyMembers = async () => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/company_memberships', 'get')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  inviteMember = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/company_memberships/invite', 'post', data)
        .then((res) => {
          message.success(`The invitation has been sent to ${data.email}.`, 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  removeMember = async (userId) => {
    return new Promise((resolve, reject) => {
      rpgRequest(`/api/company_memberships/${userId}`, 'delete')
        .then((res) => {
          message.success(`The user has been removed.`, 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  registerCompany = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/companies', 'post', data)
        .then((res) => {
          message.success('The company is created successfully.', 5);
          this.currentUser.company = res.data;
          resolve();
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  findCompany = async (companyCode) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/companies/${encodeURIComponent(companyCode)}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  joinCompany = async (companyId) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/company_memberships', 'post', { companyId })
        .then((res) => {
          message.success('You joined the company successfully.', 5);
          this.currentUser.company = res.data;
          resolve();
        })
        .catch((err) => {
          message.error(err.response.data.message, 5);
          reject(err);
        });
    });
  }

  removeCompanyMember = async (userId) => {
    return new Promise((resolve, reject) => {
      rpgRequest(`/api/company_memberships/${userId}`, 'delete')
        .then((res) => {
          message.success('You removed the member successfully.', 5);
          this.currentUser = res.data;
          resolve();
        })
        .catch((err) => {
          message.error('Failed to remove the member.', 5);
          reject(err);
        });
    });
  }

  updateCompanyInfo = async (data) => {
    return new Promise((resolve, reject) => {
      rpgRequest('/api/company', 'put', data)
        .then((res) => {
          this.currentUser = { ...this.currentUser, company: res.data };
          message.success('You updated the company info successfully.', 5);
          resolve();
        })
        .catch((err) => {
          message.error('Failed to update the company info.', 5);
          reject(err);
        });
    });
  }

  approveCompanyMember = async (userId) => {
    return new Promise((resolve, reject) => {
      rpgRequest(`/api/company_memberships/${userId}`, 'put')
        .then((res) => {
          message.success("You approved the member's request successfully.", 5);
          this.currentUser = res.data;
          resolve();
        })
        .catch((err) => {
          message.error("Failed to approve the member's request.", 5);
          reject(err);
        });
    });
  }
}

export default AuthStore;
