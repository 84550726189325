import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import cloneDeep from 'lodash.clonedeep';
import { Input, Dropdown, Menu, Select } from 'antd';

import ContainersTable from '../../components/ContainersTable';
import { UserRoleOnShipment } from '../../utils/constants.js';
import AddContainerModal from '../../components/AddContainerModal';

import './index.less';

const EMPTY_DATA_SOURCE_MAP = {
  All: [],
  OnVessel: [],
  Arrived: [],
  Discharged: [],
  GateOut: [],
  EmptyReturned: [],
  NotFound: []
};

const ShipmentContainers = (props) => {
  const { currentShipment, shipmentStore, authStore, appointmentStore } = props;

  const [showModal, setShowModal] = useState(false);
  const [containerFilter, setContainerFilter] = useState('');

  const [currentStatusFilter, setCurrentStatusFilter] = useState('All');
  const [dataSourceMap, setDataSourceMap] = useState(cloneDeep(EMPTY_DATA_SOURCE_MAP));

  useEffect(() => {
    const { containers } = shipmentStore;

    if (containers.length > 0) {
      let sourceMap = cloneDeep(EMPTY_DATA_SOURCE_MAP);
      containers.forEach(c => {
        sourceMap['All'].push(c);

        if (!!sourceMap[c.status]) {
          sourceMap[c.status].push(c);
        }
      });

      setDataSourceMap(sourceMap);
    } else {
      setDataSourceMap({});
    }
  }, [shipmentStore.containers]);

  const isAuthor = () => {
    const author = (currentShipment.collaborators || []).find(c => c.role == UserRoleOnShipment.Admin.value);
    return author?.userId == authStore.currentUser.id;
  }

  useEffect(() => {
    if (!!currentShipment.id) {
      shipmentStore.fetchAllContainers(currentShipment.id);
      shipmentStore.fetchAllCarriers();
    }
  }, [authStore.currentUser, currentShipment]);

  const download = (e) => {
    shipmentStore.downloadContainers(e.key);
  }

  return (
    <div className='shipment-containers'>
      <div className='shipment-containers__top-bar'>
        <div className='shipment-containers__top-bar--btns'>
          {
            isAuthor() ? (
              <div className='shipment-containers__top-bar--btn add' onClick={ () => setShowModal(true) }>
                <i className='fas fa-plus' />
                Add container
              </div>
            ) : null
          }

        </div>

        <div className='shipment-containers__top-bar--btns'>
          <Select
            onChange={(value) => setCurrentStatusFilter(value)}
            size='large'
            style={{
              width: 200,
            }}
            placeholder='Current Status'
            options={Object.keys(EMPTY_DATA_SOURCE_MAP).map((k) => {
              return {
                value: k,
                label: `${k.replace(/([A-Z])/g, ' $1').trim()}   (${dataSourceMap[k]?.length})`,
              };
            })}
          />

          <Input.Search
            className='shipment-containers__top-bar--btn'
            placeholder='Search container number...'
            size='large'
            onSearch={setContainerFilter}
            allowClear
            style={{
              width: 340,
            }}
          />

          <Dropdown
            className='shipment-containers__top-bar--btn'
            overlay={
              <Menu onClick={ download }>
                <Menu.Item key='xlsx'>Download .XLSX</Menu.Item>
                <Menu.Item key='csv'>Download .CSV</Menu.Item>
              </Menu>
            }
            placeholder='bottom'
          >
            <div className='shipment-containers__top-bar--btn download'>
              <i className='fa-solid fa-download' />
              Download Reports
            </div>
          </Dropdown>
        </div>
      </div>

      <div className='shipment-containers__content'>
        <ContainersTable
          carriers={shipmentStore.carriers}
          containers={dataSourceMap[currentStatusFilter]?.filter(c => c.containerNum.includes(containerFilter)) || []}
          updateContainer={shipmentStore.updateContainer(currentShipment.id)}
          isLoading={shipmentStore.isLoading}
          isAuthor={isAuthor()}
          removeContainers={shipmentStore.removeContainers(currentShipment.id)}
          createPickUpAppointment={appointmentStore.createPickUpAppointment(currentShipment.id)}
        />
      </div>

      <AddContainerModal
        visible={ showModal }
        onOk={ shipmentStore.addMoreContainer }
        onCancel={ () => setShowModal(false) }
        shipmentId={currentShipment.id}
        carriers={shipmentStore.carriers}
        searchCarrier={shipmentStore.searchCarrier}
      />
    </div>
  );
};

export default inject('shipmentStore', 'authStore', 'appointmentStore')(observer(ShipmentContainers));
