import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';

import './index.less';

const CompanyProfileInfo = (props) => {
  const { company, isAdmin, updateCompanyInfo } = props;

  const [isShowModal, setIsShowModal] = useState(false);
  const [form] = Form.useForm();

  const updateCompany = () => {
    form.validateFields()
      .then((values) => {
        updateCompanyInfo(values);
        setIsShowModal(false);
      })
      .catch((errs) => {
        console.error(errs);
      });
  }

  return (
    <div className='account-info'>
      <div className='account-info__account-block'>
        <div className='account-info__account-block--label'>
          Company Settings
          {
            isAdmin ? (
              <div className='account-info__account-block--label-btn' onClick={() => setIsShowModal(true)}>
                <EditOutlined style={{marginRight: '8px'}} />
                Edit
              </div>
            ) : null
          }
        </div>

        <div className='account-info__account-block--content'>
          <div className='account-info__account-block--info-row'>
            <div className='account-info__account-block--info-block'>
              <label>Company Name</label>
              <span className={isAdmin ? '' : 'disabled'}>{ company?.name }</span>
            </div>

            <div className='account-info__account-block--info-block'>
              <label>Company Code</label>
              <span className='disabled'>{ company?.companyCode }</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title='Company Setting'
        okText='Confirm'
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        onOk={updateCompany}
      >
        <Form
          form={form}
          initialValues={company}
          layout='vertical'
          size='large'
        >
          <Form.Item
            label='Company Name'
            name='name'
            rules={[{ required: true }]}
          >
            <Input placeholder='Company Name' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

CompanyProfileInfo.propTypes = {
  company: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
};

export default CompanyProfileInfo;
