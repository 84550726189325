import { makeAutoObservable, observable } from "mobx";
import update from 'react-addons-update';
import { message } from 'antd';
import moment from 'moment';

import rpgRequest from '../lib/rpgRequest.js';

const SOCKET_TYPE = 'APPOINTMENT';

class AppointmentStore {
  @observable pickUpAppointments = [];
  @observable deliveryAppointments = [];
  @observable isLoading = false;

  constructor(socketClient, shipmentStore) {
    this.socket = socketClient.socket;
    this.shipmentStore = shipmentStore;

    makeAutoObservable(this);
  }

  fetchAllActiveAppointments = () => {
    this.isLoading = true;

    rpgRequest('/api/fba_deliveries')
      .then((res) => {
        this.isLoading = false;
        this.deliveryAppointments = res.data;
      })
      .catch((err) => {
        this.isLoading = false;
        message.error('The 3rd part service is invalid.', 5);
      });
  }

  requestAppointment = (data, callback = () => {}, errCallback = () => {}) => {
    this.isLoading = true;

    rpgRequest('/api/fba_deliveries', 'post', data)
      .then((res) => {
        message.success('Request appointments successfully.', 5);
        this.isLoading = false;
        callback(res.data);
      })
      .catch((err) => {
        this.isLoading = false;
        message.error('The 3rd part service is invalid.', 5);
        errCallback(err);
      });
  }

  fetchAppointmentDetail = (appointmentId, callback = () => {}, errCallback = () => {}) => {
    rpgRequest(`/api/fba_deliveries/${appointmentId}`, 'get')
      .then((res) => {
        // message.success('Get appointment detail successfully.', 5);

        const index = this.deliveryAppointments.map(a => a.id).indexOf(res.data.id);
        this.deliveryAppointments = update(this.deliveryAppointments, {
          [index]: {
            $set: res.data,
          }
        });
        callback(res.data);
      })
      .catch((err) => {
        // message.error('The 3rd part service is invalid.', 5);
        errCallback(err);
      });
  }

  updateAppointment = (appointmentId, data, callback = () => {}, errCallback = () => {}) => {
    this.isLoading = true;

    rpgRequest(`/api/fba_deliveries/${appointmentId}`, 'put', data)
      .then((res) => {
        message.success('Update appointments successfully.', 5);
        this.isLoading = false;
        callback(res.data);
      })
      .catch((err) => {
        this.isLoading = false;
        message.error('The 3rd part service is invalid.', 5);
        errCallback(err);
      });
  }

  cancelAppointment = (appointmentId, fc, callback = () => {}) => {
    this.isLoading = true;

    rpgRequest(`/api/fba_deliveries/${appointmentId}`, 'delete', { fc })
      .then((res) => {
        message.success('The appointment is cancelled successfully.', 5);
        this.isLoading = false;

        const index = this.deliveryAppointments.map(a => a.id).indexOf(res.data.id);
        this.deliveryAppointments = update(this.deliveryAppointments, {
          [index]: {
            $set: res.data
          }
        });
        callback(res.data);
      })
      .catch((err) => {
        this.isLoading = false;
        message.error('The 3rd part service is invalid.', 5);
      });
  }

  deleteAppointment = (appointmentId, callback = () => {}) => {
    this.isLoading = true;

    rpgRequest(`/api/fba_deliveries/${appointmentId}/archive`, 'put')
      .then((res) => {
        message.success('The appointment is deleted successfully.', 5);
        this.isLoading = false;
        this.deliveryAppointments = this.deliveryAppointments.filter((appointment) => {
          return appointment.id != res.data.id;
        });
        callback(res.data);
      })
      .catch((err) => {
        this.isLoading = false;
        message.error('The 3rd part service is invalid.', 5);
      });
  }

  fetchAllPickUpAppointments = () => {
    this.isLoading = true;
    rpgRequest('/api/appointments')
      .then((res) => {
        this.pickUpAppointments = res.data;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        message.error('Server error.', 5);
      });
  }

  createPickUpAppointment = (shipmentId) => (containerId, pickUpAt, files, callback = () => {}) => {
    this.socket.emit(SOCKET_TYPE, 'CREATE_PICK_UP_APPOINTMENT', {
      readableId: `APPT${moment(pickUpAt).format('YYYYMMDDHHmmss')}`,
      containerId,
      pickUpAt,
      shipmentId,
      files
    }, (data) => {
      const idx = this.shipmentStore.containers.map(c => c.id).indexOf(data.containerId);
      this.shipmentStore.containers = update(this.shipmentStore.containers, {
        [idx]: {
          appointment: {
            $set: data,
          }
        }
      });

      this.pickUpAppointments.push(data);
      callback(data);
      message.success('The appointment is created successfully.', 5);
    });
  }

  updatePickUpAppointment = (appointment, pickUpAt, files, callback) => {
    this.socket.emit(SOCKET_TYPE, 'UPDATE_PICK_UP_APPOINTMENT', {
      id: appointment.key,
      shipmentId: appointment.shipmentId,
      pickUpAt,
      files
    }, (data) => {
      const idx = this.shipmentStore.containers.map(c => c.id).indexOf(data.containerId);
      this.shipmentStore.containers = update(this.shipmentStore.containers, {
        [idx]: {
          appointment: {
            $set: data,
          }
        }
      });

      const index = this.pickUpAppointments.map(a => a.id).indexOf(data.id);
      this.pickUpAppointments = update(this.pickUpAppointments, {
        [index]: {
          $set: data,
        }
      });
      callback(data);
      message.success('The appointment is updated successfully.', 5);
    });
  }

  deletePickUpAppointment = (shipmentId, appointmentId) => {
    rpgRequest(`/api/shipments/${shipmentId}/appointments/${appointmentId}`, 'delete')
      .then((res) => {
        this.pickUpAppointments = this.pickUpAppointments.filter(a => a.id != appointmentId);
        message.success('The appointment is deleted successfully.', 5);
      })
      .catch((err) => {
        message.error('Server error', 5);
      });
  }
};

export default AppointmentStore;
