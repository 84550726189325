import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Modal, Form, Input } from 'antd';

import './index.less';

const CreateShipmentModal = (props) => {
  const { visible } = props;
  const { onCancel, createShipment, goTo } = props;

  const [form] = Form.useForm();

  const newShipment = ({title, description}) => {
    const serializedValues = {
      title,
      description: description && !!description.trim() ? description.trim() : undefined,
    };
    createShipment(serializedValues, (shipment) => {
      onCancel();
      goTo(`/dashboard/shipments/${shipment.id}`)
    });
  }

  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onCancel();
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  return (
    <Modal
      className='create-shipment-modal'
      open={visible}
      onCancel={ onCancel }
      title='Create shipment'
      footer={null}
    >

      <Form form={form} onFinish={newShipment} layout='vertical'>
        <div className='create-shipment-modal__body'>
          <Form.Item
            name='title'
            label='Shipment Title'
            rules={[
              {
                required: true,
                message: 'Please input the shipment title.',
              },
              {
                min: 2,
                message: 'The lenght of title should be greater than 2.',
              },
              {
                max: 40,
                message: 'The lenght of title should be less than 40.',
              }
            ]}
          >
            <Input placeholder='e.g. Bol Number/Container Number' size='large' />
          </Form.Item>

          <Form.Item
            name='description'
            label='Description'
            rules={[
              {
                max: 120,
                message: 'The lenght of description should be less than 120.',
              },
              {
                min: 4,
                message: 'The lenght of description should be greater than 4.',
              }
            ]}
          >
            <Input.TextArea placeholder='Background Information/Explanation' rows={6} />
          </Form.Item>
        </div>

        <div className='create-shipment-modal__footer'>
          <button className='create-shipment-modal__footer--btn cancel' onClick={onClose}>
            Cancel
          </button>

          <button className='create-shipment-modal__footer--btn create'>
            Create
          </button>
        </div>
      </Form>
    </Modal>
  );
};

CreateShipmentModal.propTypes = {
  goTo: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  createShipment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CreateShipmentModal;
