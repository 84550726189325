import React from 'react';
import PropTypes from 'prop-types';

import ContainersTable from '../ContainersTable';

import './index.less';

const ContainersOverviewBlock = (props) => {
  const { isLoading, containers, carriers, isAuthor, updateContainer, removeContainers, createPickUpAppointment } = props;

  return (
    <div className='containers-overview-block'>
      <ContainersTable
        containers={containers}
        carriers={carriers}
        updateContainer={updateContainer}
        isLoading={isLoading}
        isAuthor={isAuthor}
        removeContainers={removeContainers}
        createPickUpAppointment={createPickUpAppointment}
      />
    </div>
  );
};

ContainersOverviewBlock.propTypes = {
  containers: PropTypes.array.isRequired,
  carriers: PropTypes.array.isRequired,
  updateContainer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  removeContainers: PropTypes.func.isRequired,
  createPickUpAppointment: PropTypes.func.isRequired,
};

export default ContainersOverviewBlock;
