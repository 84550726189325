import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";

import { useParams } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';

import ShipmentDetailBlock from '../../components/ShipmentDetailBlock';
import BigNumberOverview from '../../components/BigNumberOverview';
import ContainersOverviewBlock from '../../components/ContainersOverviewBlock';
import ScheduleOverview from '../../components/ScheduleOverview';
import MattersOverview from '../../components/MattersOverview';
import AddContainerModal from '../../components/AddContainerModal';
import { UserRoleOnShipment } from '../../utils/constants.js';

import './index.less';

const ShipmentOverview = (props) => {
  const { currentShipment, authStore } = props;
  const { isLoading, carriers, containers, addMoreContainer, updateContainer, updateCollaboratorRole, removeCollaborator, fetchAllContainers, updateETA, removeContainers, addCollaborators, fetchAllCarriers, searchCarrier } = props.shipmentStore;
  const { createPickUpAppointment } = props.appointmentStore;
  const { collaboratorRelations } = props.collaboratorStore;

  const [showModal, setShowModal] = useState(false);

  const isAuthor = () => {
    const author = (currentShipment.collaborators || []).find(c => c.role == UserRoleOnShipment.Admin.value);
    return author?.userId == authStore.currentUser.id;
  }

  useEffect(() => {
    if (!!currentShipment.id) {
      fetchAllContainers(currentShipment.id);
      fetchAllCarriers();
    }
  }, [authStore.currentUser, currentShipment]);

  const download = (e) => {
    props.shipmentStore.downloadContainers(e.key);
  }

  return (
    <div className='shipment-overview'>
      <div className='shipment-overview__full-width'>
        <div className='shipment-overview__block'>
          <ShipmentDetailBlock
            currentUser={props.authStore.currentUser}
            shipment={currentShipment}
            updateCollaboratorRole={updateCollaboratorRole}
            removeCollaborator={removeCollaborator}
            updateETA={updateETA}
            isAuthor={isAuthor()}
            collaboratorRelations={collaboratorRelations}
            addCollaborators={addCollaborators}
          />
        </div>

        <div className='shipment-overview__block'>
          <div className='shipment-overview__block--label'>
            <label>Containers</label>

            <div className=''>
              <Dropdown
                overlay={
                  <Menu onClick={ download }>
                    <Menu.Item key='xlsx'>Download .XLSX</Menu.Item>
                    <Menu.Item key='csv'>Download .CSV</Menu.Item>
                  </Menu>
                }
                placeholder='bottom'
              >
                <i className='fa-solid fa-download' />
              </Dropdown>

              {
                isAuthor() ? (
                  <i className='fa-solid fa-circle-plus' onClick={ () => setShowModal(true) } />
                ) : null
              }
            </div>
          </div>

          <div className='shipment-overview__block--body'>
            <ContainersOverviewBlock
              containers={containers}
              carriers={carriers}
              updateContainer={updateContainer(currentShipment.id)}
              isLoading={isLoading}
              isAuthor={isAuthor()}
              removeContainers={removeContainers(currentShipment.id)}
              createPickUpAppointment={createPickUpAppointment(currentShipment.id)}
            />
          </div>
        </div>

        {/*
        <div className='shipment-overview__block'>
          <div className='shipment-overview__block--label'>
            <label>Schedule</label>
          </div>

          <div className='shipment-overview__block--body'>
            <ScheduleOverview />
          </div>
        </div>
        */}
      </div>

      {/*
      <div className='shipment-overview__right-block'>
        <div className='shipment-overview__block'>
          <BigNumberOverview />
        </div>

        <div className='shipment-overview__block'>
          <div className='shipment-overview__block--label'>
            <label>New Matters</label>
            <i className='fa-solid fa-circle-plus' />
          </div>

          <div className='shipment-overview__block--body'>
            <MattersOverview />
          </div>
        </div>
      </div>
      */}

      <AddContainerModal
        visible={ showModal }
        onOk={ addMoreContainer }
        onCancel={ () => setShowModal(false) }
        shipmentId={ currentShipment.id }
        carriers={ carriers }
        searchCarrier={ searchCarrier }
      />
    </div>
  );
};

export default inject("authStore", "shipmentStore", "appointmentStore", "collaboratorStore")(observer(ShipmentOverview));
