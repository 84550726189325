import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";

import AppointmentsLayout from '../../layouts/AppointmentsLayout';
import AppointmentsTable from '../../components/AppointmentsTable';

import './index.less';

const AllAppointments = (props) => {
  const { currentUser } = props.authStore;
  const { isLoading, pickUpAppointments, fetchAllPickUpAppointments, deletePickUpAppointment, updatePickUpAppointment } = props.appointmentStore;

  useEffect(() => {
    fetchAllPickUpAppointments();
  }, []);

  return (
    <AppointmentsLayout>
      <div className='all-appointments'>
        <div className='all-appointments__label'>My appointments</div>

        <div className='all-appointments__content'>
          <AppointmentsTable
            appointments={pickUpAppointments}
            isLoading={isLoading}
            onDelete={deletePickUpAppointment}
            currentUser={currentUser}
            updatePickUpAppointment={updatePickUpAppointment}
          />
        </div>
      </div>
    </AppointmentsLayout>
  );
};

export default inject("authStore", "appointmentStore")(observer(AllAppointments));
