import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import debounce from 'lodash.debounce';
import { Input } from 'antd';

import ChannelList from '../../components/ChannelList';
import ChannelChat from '../../components/ChannelChat';
import InviteCollaborators from '../../components/InviteCollaborators';
import { UserRoleOnShipment } from '../../utils/constants.js';

import './index.less';

const ChannelContainer = (props) => {
  const { currentUser } = props.authStore;
  const { channels, sortedChannels, currentChannelId } = props.channelStore;
  const { selectChannel, sendMessage, calculateMessagesCount, readMessages, fetchMoreMessages } = props.channelStore;
  const { addCollaborators, updateCollaboratorRole, removeCollaborator } = props.shipmentStore;
  const { collaboratorRelations } = props.collaboratorStore;

  const [showInviteCollaborators, setShowInviteCollaborators] = useState(false);

  const [keywords, setKeywords] = useState('');
  const search = (e) => {
    setKeywords(e.target.value);
  }

  const filteredChannels = !!keywords.trim() ? sortedChannels.filter(c => c.shipment.title.toLowerCase().includes(keywords.toLowerCase())) : sortedChannels;

  const currentChannel = channels[currentChannelId];

  const goToDetailPage = () => {
    props.routing.push(`/dashboard/shipments/${currentChannel.shipment.id}`);
  }

  const isAuthor = () => {
    const author = (channels[currentChannelId].shipment.collaborators || []).find(c => c.role == UserRoleOnShipment.Admin.value);
    return author?.userId == currentUser.id;
  }

  if (Object.keys(channels).length == 0) {
    return (
      <div className='channel-container'>
        <div className='channel-container__placeholder'>
          <span>You have not joined any channel.</span>
        </div>
      </div>
    );
  }

  return (
    <div className='channel-container'>
      <div className='channel-container__list'>
        <div className='channel-container__list--header'>
          <Input
            size='large'
            placeholder='Search channel name'
            prefix={<i className='fa-solid fa-search' />}
            onChange={ debounce(search, 500) }
          />
        </div>

        <div className='channel-container__list--body'>
          <ChannelList
            channels={filteredChannels}
            currentChannelId={currentChannelId}
            selectChannel={selectChannel}
            currentUserId={currentUser.id}
            calculateMessagesCount={calculateMessagesCount}
          />
        </div>
      </div>

      <div className='channel-container__channel-body'>
        <div className='channel-container__channel-body--header'>
          <div className='channel-container__channel-body--header-info rpg-text-limit--one-line'>
            { currentChannel?.shipment.title }
            <span className='divider' />
            <span className='desc'>{ currentChannel?.shipment.description }</span>
          </div>

          <div className='channel-container__channel-body--header-btns'>
            <i className='fa-regular fa-file-lines' onClick={goToDetailPage} />
            {
              isAuthor() ? (
                <i className='fa-solid fa-user-group' onClick={() => setShowInviteCollaborators(true)} />
              ) : null
            }
          </div>
        </div>

        <div className='channel-container__channel-body--body'>
          <ChannelChat
            channel={currentChannel}
            sendMessage={sendMessage}
            currentUserId={currentUser.id}
            readMessages={readMessages}
            fetchMoreMessages={fetchMoreMessages}
          />
        </div>
      </div>

      <InviteCollaborators
        visible={showInviteCollaborators}
        onCancel={() => setShowInviteCollaborators(false)}
        shipment={currentChannel?.shipment}
        updateCollaboratorRole={updateCollaboratorRole}
        removeCollaborator={removeCollaborator}
        isAdmin={isAuthor()}
        collaboratorRelations={collaboratorRelations}
        addCollaborators={addCollaborators}
      />
    </div>
  );
};

export default inject('routing', 'channelStore', 'authStore', 'shipmentStore', 'collaboratorStore')(observer(ChannelContainer));
