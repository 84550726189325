import { makeAutoObservable, observable } from "mobx";
import update from 'react-addons-update';
import { message } from 'antd';
import uniq from 'lodash.uniq';
import moment from 'moment';
import XLSX from 'xlsx';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
// import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';

import { sheet2blob } from '../utils/DownloadFileUtil';
import { TerminalLinks } from '../utils/constants';

import rpgRequest from '../lib/rpgRequest.js';

const SOCKET_TYPE = 'SHIPMENT';

class ShipmentStore {
  @observable currentShipment = {};
  @observable shipments = [];
  @observable containers = [];
  @observable carriers = [];
  @observable isLoading = false;

  constructor(socketClient, channelStore) {
    this.socket = socketClient.socket;
    this.channelStore = channelStore;

    makeAutoObservable(this);
  }

  init = () => {
    this._initEventListener();
  }

  _initEventListener = () => {
    this.socket.on(SOCKET_TYPE, (action, data) => {
      switch (action) {
        case 'UPDATE_SHIPMENT': {
          const index = this.shipments.map(s => s.id).indexOf(data.id);
          if (index < 0) {
            this.fetchAllShipments();
          } else {
            this.shipments = update(this.shipments, {
              [index]: {
                $set: data
              }
            });
          }
          break;
        }
        case 'UPDATE_CONTAINERS': {
          if (data.shipmentId == this.currentShipment.id) {
            this.fetchAllContainers(data.shipmentId, () => {}, false);
          }
          break;
        }
      }
    });
  }

  _containerUpdatedNotification = () => {
    message.success('Containers updated.');
  }

  setCurrentShipment = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) {
        this.currentShipment = {};
        resolve({});
        return;
      }

      this.getShipmentById(id)
        .then((shipment) => {
          this.currentShipment = shipment;
          resolve(shipment);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchAllShipments = () => {
    this.isLoading = true;
    rpgRequest('/api/shipments')
      .then((res) => {
        res.data.forEach((shipment) => {
          shipment.status = calcShipmentStatus(shipment);
        });
        this.shipments = orderBy(res.data, ['status', 'created_at'], ['asc', 'desc']);
        this.isLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.isLoading = false;
      });
  }

  fetchAllCarriers = () => {
    rpgRequest('/api/carriers')
      .then((res) => {
        this.carriers = orderBy(res.data, 'scac_code');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getShipmentById = (id) => {
    return new Promise((resolve, reject) => {
      const shipment = this.shipments.find((s) => s.id == id);
      if (!!shipment) {
        resolve(shipment);
        return;
      }

      rpgRequest(`/api/shipments/${id}`)
        .then((res) => {
          const newShipment = {...res.data, status: calcShipmentStatus(res.data)};
          this.shipments.push(newShipment);
          resolve(newShipment);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createShipment = (params, callback = () => {}, errCallback = () => {}) => {
    rpgRequest('/api/shipments', 'post', params)
      .then((res) => {
        message.success('The shipment is created successfully.', 5);
        const newShipment = {...res.data, status: calcShipmentStatus(res.data)};
        this.shipments.push(newShipment);
        callback(newShipment);
      })
      .catch((err) => {
        message.error('Failed to create the shipment.', 5);
        errCallback(err);
      });
  }

  fetchAllContainers = (shipmentId, callback = () => {}, shouldLoading = true) => {
    this.isLoading = shouldLoading;
    if (shouldLoading) {
      this.containers = [];
    }
    rpgRequest(`/api/shipments/${shipmentId}/containers`)
      .then((res) => {
        this.containers = orderBy(res.data, ['created_at', 'containerNum'], ['desc', 'asc']);
        this.isLoading = false;
        callback(res);
      })
      .catch((err) => {
        message.error('The 3rd part service is invalid.', 5);
        this.isLoading = false;
      });
  }

  addMoreContainer = (shipmentId, params) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}/containers`, 'post', params)
        .then(() => {
          this.fetchAllContainers(shipmentId);
          resolve();
        })
        .catch((err) => {
          message.error('The container number is invalid.', 5);
          resolve();
        });
    });
  }

  updateContainer = (shipmentId) => (containerId, scacCode) => {
    this.isLoading = true;
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}/containers/${containerId}`, 'put', { scacCode })
        .then(() => {
          this.fetchAllContainers(shipmentId);
          resolve();
        })
        .catch(() => {
          this.isLoading = false;
          message.error('Server error', 5);
          resolve();
        });
    });
  }

  addCollaborators = (shipmentId, collaboratorIds, role) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}/collaborators`, 'post', { collaboratorIds, role })
        .then((res) => {
          message.success("The collaborators are invited.", 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error("Failed to add the collaborators.", 5);
          console.error(err);
        });
    });
  }

  updateCollaboratorRole = (shipmentId, userId, role) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}/collaborators/${userId}`, 'put', { role })
        .then((res) => {
          const index = this.shipments.map(s => s.id).indexOf(res.data.id);
          const newShipment = {...res.data, status: calcShipmentStatus(res.data)};
          this.shipments = update(this.shipments, {
            [index]: {
              $set: newShipment
            }
          });

          if (!!res.data.chatChannel) {
            this.channelStore.channels = update(this.channelStore.channels, {
              [res.data.chatChannel.id]: {
                shipment: {
                  $set: newShipment
                }
              }
            });
          }

          message.success("The collaborator's role is updated successfully.", 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error("Failed to update the collaborator's role.", 5);
          console.error(err);
        });
    });
  }

  completeShipment = (shipmentId) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}/complete`, 'put')
        .then((res) => {
          const index = this.shipments.map(s => s.id).indexOf(res.data.id);
          const newShipment = {...res.data, status: calcShipmentStatus(res.data)};
          this.shipments = update(this.shipments, {
            [index]: {
              $set: newShipment
            }
          });

          if (!!res.data.chatChannel) {
            this.channelStore.channels = update(this.channelStore.channels, {
              [res.data.chatChannel.id]: {
                shipment: {
                  $set: newShipment
                }
              }
            });
          }

          message.success("The shipment is marked as archived.", 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error("Failed to mark the shipment as compleleted.", 5);
        });
    });
  }

  deleteShipment = (shipmentId) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}`, 'delete')
        .then((res) => {
          const index = this.shipments.map(s => s.id).indexOf(res.data.id);
          this.shipments = update(this.shipments, {
            $splice: [[index], [1]]
          });

          message.success("The shipment is deleted.", 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error("Failed to delete the shipment.", 5);
        });
    });
  }

  removeCollaborator = (shipmentId, userId) => {
    return new Promise((resolve) => {
      rpgRequest(`/api/shipments/${shipmentId}/collaborators/${userId}`, 'delete')
        .then((res) => {
          const index = this.shipments.map(s => s.id).indexOf(res.data.id);
          this.shipments = update(this.shipments, {
            [index]: {
              $set: res.data,
            }
          });

          if (!!res.data.chatChannel) {
            this.channelStore.channels = update(this.channelStore.channels, {
              [res.data.chatChannel.id]: {
                shipment: {
                  $set: res.data
                }
              }
            });
          }

          message.success("The collaborator is removed successfully.", 5);
          resolve(res.data);
        })
        .catch((err) => {
          message.error("Failed to remove the collaborator.", 5);
        });
    });
  }

  updateETA = (shipmentId, etaString) => {
    return rpgRequest(`/api/shipments/${shipmentId}`, 'put', { eta: etaString })
      .then((res) => {
        const index = this.shipments.map(s => s.id).indexOf(res.data.id);
        const newShipment = {...res.data, status: calcShipmentStatus(res.data)};
        this.shipments = update(this.shipments, {
          [index]: {
            $set: newShipment
          }
        });

        message.success("The shipment's ETA is updated successfully.", 5);
      })
      .catch((err) => {
        message.error("Failed to update the shipment ETA.", 5);
      });
  }

  removeContainers = (shipmentId) => (containerIds) => {
    return rpgRequest(`/api/shipments/${shipmentId}/containers`, 'delete', containerIds)
      .then((res) => {
        this.containers = this.containers.filter(c => containerIds.indexOf(c.id) < 0);

        message.success("The containers are deleted successfully.", 5);
      })
      .catch((err) => {
        console.error(err);
        message.error("Failed to delete containers.", 5);
      });
  }

  searchCarrier = (containerNum, bol) => {
    let trackingNumber = containerNum;
    if (!!bol) {
      trackingNumber = bol;
    }

    return rpgRequest(`/api/carriers/search`, 'post', { trackingNumber });
  }

  downloadContainers = (format) => {
    switch (format) {
      case "xlsx": {
        this._downloadXLSXFile();
        break;
      }
      case "csv": {
        this._downloadCSVFile();
        break;
      }
      default: {
        break;
      }
    }
  }

  createChannelByShipment = (shipmentId, callback = () => {}, errCallback = () => {}) => {
    this.channelStore.createChannel(shipmentId, (data) => {
      const idx = this.shipments.map(s => s.id).indexOf(shipmentId);
      this.shipments = update(this.shipments, {
        [idx]: {
          $set: {
            chatChannel: data
          }
        }
      });
      callback(data);
    }, errCallback);
  }

  _downloadXLSXFile = () => {
    let arrayData = this._generateCSVArrayData(true);
    let sheet = XLSX.utils.aoa_to_sheet(arrayData);
    this._triggerDownload(sheet2blob(sheet), "xlsx");
  }

  _downloadCSVFile = (tabSeperated) => {
    const csvContent = this._generateCSV(tabSeperated);
    const type = 'text/csv;charset=utf-8';
    const extension = 'csv';

    let csvData = new Blob(["\ufeff", csvContent], { type: type });
    this._triggerDownload(csvData, extension);
  }

  _generateCSVArrayData = (noNeedQuote) => {
    const columns = ["Container No", "Terminal", "Holds status", "Discharged Date", "Pick Up Appt", "Last Free Day", "Outgated", "Delivery Appt", "Actual Delivered Date", "Actual Empty Return Date"];

    const rows = this.containers.map((container) => {
      const terminalCode = container.terminal_code;
      const terminalInfo = TerminalLinks[terminalCode];

      const hold = (container.holds_info || []).find(h => (h.status == 'HOLD' || h.status == 'Hold'));
      const holdInfo = hold ? (hold.detail ? hold.detail.reason : `${hold.type} Hold`) : "";
      const dischargedEvent = container.events_info.events.find((e) => e.desc == 'Discharged');
      const dischargedDate = dischargedEvent ? moment(dischargedEvent.eventTime).format('MM/DD/YYYY') : '';
      const lastFreeDay = container.last_free_day ? moment(container.last_free_day).format('MM/DD/YYYY') : '';
      const outgateEvent = container.events_info.events.find((e) => e.desc == 'GateOut');
      const outgated = outgateEvent ? moment(outgateEvent.eventTime).format('MM/DD/YYYY') : '';
      const emptyReturnEvent = container.events_info.events.find((e) => e.desc == 'EmptyReturned');
      const emptyReturnDate = emptyReturnEvent ? moment(emptyReturnEvent.eventTime).format('MM/DD/YYYY') : '';

      return [
        container.containerNum,
        terminalInfo?.name,
        holdInfo,
        dischargedDate,
        "",
        lastFreeDay,
        outgated,
        "",
        "",
        emptyReturnDate,
      ];
    });

    return [columns].concat(rows);
  }

  _generateCSV = (tabSeperated) => {
    let rows = this._generateCSVArrayData();

    let delimiter = ",";

    if (!!tabSeperated) {
      delimiter = "\t";
    }

    return rows.map(e=>e.join(delimiter)).join("\n");
  }

  _triggerDownload = (blobUrl, fileExtension) => {
    var csvUrl = URL.createObjectURL(blobUrl);

    let a = window.document.createElement("a");
    a.href = csvUrl;
    a.target = "_blank";
    a.download = `containers-${moment().format('YYYY-MM-DD')}.${fileExtension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export const calcShipmentStatus = (shipment) => {
  if (!!shipment.completed) {
    return "Completed";
  }

  if (!shipment.containers?.length) {
    return "Active";
  }

  const allStatus = uniq((shipment.containers || []).map(c => c.status));

  if (uniq(allStatus.concat(["GateOut", "EmptyReturned"])).length == 2) {
    return "Completed";
  }

  return "Active";
}

export default ShipmentStore;
