import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import MentionsPlugin from "./plugins/MentionsPlugin";
import UpdateContentPlugin from "./plugins/UpdateContentPlugin";
import KeyPressPlugin from "./plugins/KeyPressPlugin";
import { MentionNode } from "./nodes/MentionNode";
import RPGTheme from "./themes/RPGTheme";

import './index.less';

const editorConfig = {
  theme: RPGTheme,
  onError(error) {
    throw error;
  },
  nodes: [MentionNode]
};

const Editor = (props) => {
  const onChange = (editorState) => {
    editorState.read(() => {
      props.onChange(editorState.toJSON());
    });
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <PlainTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
        />

        <MentionsPlugin
          mentionableUsers={props.mentionableUsers}
        />

        <UpdateContentPlugin
          content={props.value}
          updateTimestamp={props.updateTimestamp}
        />

        <OnChangePlugin
          onChange={onChange}
        />

        <KeyPressPlugin
          content={props.value}
          onKeyDown={props.onKeyDown}
        />
      </div>
    </LexicalComposer>
  );
}

function Placeholder() {
  return (
    <div className="editor-placeholder">
      Play around with the mentions plugin...
    </div>
  );
}

export default Editor;
