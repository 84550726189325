import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import { Form, Input, Button } from 'antd';

import ResetPassword from './reset.js';

import './index.less';

const ForgetPassword = (props) => {
  const { authStore, routing } = props;
  const [currentEmail, setCurrentEmail] = useState();

  const [form] = Form.useForm();

  const requestResetPassword = (data) => {
    authStore.requestResetPassword(data)
      .then(() => {
        setCurrentEmail(data.email);
      });
  }

  const resetPassword = (data) => {
    authStore.resetPassword(data)
      .then(() => {
        setCurrentEmail(undefined);
        routing.push('/signin');
      });
  }

  if (currentEmail) {
    return (
      <ResetPassword email={currentEmail} resetPassword={resetPassword} />
    );
  }

  return (
    <div className='forget-password'>
      <div className='signin-layout__title with-subtitle'>Reset your password</div>
      <div className='signin-layout__subtitle'>Enter the email address for your account and we'll send you a temporary link to reset your password.</div>

      <Form
        form={form}
        className='signin-layout__form'
        layout="vertical"
        size='large'
        onFinish={requestResetPassword}
      >
        <Form.Item
          name='email'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Email</span>}
          rules={[{ required: true }]}
        >
          <Input placeholder='Email' />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' className='signin-layout__submit-button'>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default inject('authStore', 'routing')(observer(ForgetPassword));
