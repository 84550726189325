import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';

import './index.less';

const AccountInfo = (props) => {
  const { isAdmin, currentUser, updateUserProfile } = props;

  const [isShowModal, setIsShowModal] = useState(false);
  const [form] = Form.useForm();

  const updateProfile = () => {
    form.validateFields()
      .then((values) => {
        updateUserProfile(values);
        setIsShowModal(false);
      })
      .catch((errs) => {
        console.error(errs);
      });
  }

  return (
    <div className='account-info'>
      <div className='account-info__account-block'>
        <div className='account-info__account-block--label'>
          Profile Settings
          <div className='account-info__account-block--label-btn' onClick={() => setIsShowModal(true)}>
            <EditOutlined style={{marginRight: '8px'}} />
            Edit
          </div>
        </div>

        <div className='account-info__account-block--content'>
          <div className='account-info__account-block--info-row'>
            <div className='account-info__account-block--info-block'>
              <label>First Name</label>
              <span>{ currentUser.first_name }</span>
            </div>

            <div className='account-info__account-block--info-block'>
              <label>Last Name</label>
              <span>{ currentUser.last_name }</span>
            </div>
          </div>

          <div className='account-info__account-block--info-row'>
            <div className='account-info__account-block--info-block'>
              <label>Email</label>
              <span className='disabled'>{ currentUser.email }</span>
            </div>

            <div className='account-info__account-block--info-block'>
              <label>Phone</label>
              <span>{ currentUser.phone_number }</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title='Profile Setting'
        okText='Confirm'
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        onOk={updateProfile}
      >
        <Form
          form={form}
          initialValues={currentUser}
          layout='vertical'
          size='large'
        >
          <Form.Item
            label='First Name'
            name='first_name'
            rules={[
              { required: true, message: 'Please input your first name!' },
              { type: 'string', min: 2, message: 'At least 2 characters required.' },
              { type: 'string', max: 10, message: 'Cannot exceed 10 characters!' },
              { type: 'string', whitespace: true, message: 'Cannot only contain whitespaces.' }
            ]}
          >
            <Input placeholder='First Name' />
          </Form.Item>

          <Form.Item
            label='Last Name'
            name='last_name'
            rules={[
              { required: true, message: 'Please input your last name!' },
              { type: 'string', min: 2, message: 'At least 2 characters required.' },
              { type: 'string', max: 10, message: 'Cannot exceed 10 characters!' },
              { type: 'string', whitespace: true, message: 'Cannot only contain whitespaces.' }
            ]}
          >
            <Input placeholder='Last Name' />
          </Form.Item>

          <Form.Item
            label='Phone'
            name='phone_number'
            rules={[{ required: true, message: 'Please input your phone number!' }]}
          >
            <Input placeholder='Phone' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

AccountInfo.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
};

export default AccountInfo;
