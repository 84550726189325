import React from 'react';
import { inject, observer } from "mobx-react";
import { Route, Redirect, Switch } from "react-router";

import DashboardLayout from '../../layouts/DashboardLayout';
import ShipmentLayout from '../../layouts/ShipmentLayout';
import SignInLayout from '../../layouts/SignInLayout';
import SignUpLayout from '../../layouts/SignUpLayout';

import SignIn from '../SignIn';
import SignUp from '../SignUp';
import ForgetPassword from '../ForgetPassword';
import CreateCompany from '../CreateCompany';
import JoinCompany from '../JoinCompany';

import Profile from '../Profile';
import AllShipments from '../AllShipments';
import ShipmentOverview from '../ShipmentOverview';
import ShipmentContainers from '../ShipmentContainers';
import ChannelContainer from '../ChannelContainer';
import CollaboratorsList from '../CollaboratorsList';
import ShipmentFiles from '../ShipmentFiles';
import AllAppointments from '../AllAppointments';
import WatchList from '../WatchList';
import VesselSchedule from '../VesselSchedule';

import AdminCronLogs from '../AdminCronLogs';
import AdminContainersCache from '../AdminContainersCache';
import AdminUsers from '../AdminUsers';
import AdminShipments from '../AdminShipments';
import AdminContainers from '../AdminContainers';

import './index.less';

import { TEMP_ADMIN_EMAILS } from '../../utils/constants';

const App = (props) => {
  const { isLoading, currentUser } = props.authStore;

  if (isLoading) {
    return (
      <div className='loading'>
        <div className='loading__placeholder'>
          <i className="fas fa-spinner" />
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <Switch>
        <Route path='/signin'>
          <SignInLayout>
            <SignIn />
          </SignInLayout>
        </Route>

        <Route path='/forget-password'>
          <SignInLayout>
            <ForgetPassword />
          </SignInLayout>
        </Route>

        <Route path='/signup'>
          <SignUpLayout>
            <SignUp />
          </SignUpLayout>
        </Route>

        <Redirect to='/signin' />
      </Switch>
    );
  }

  if (!!currentUser && !currentUser.email_confirmed) {
    return (
      <Switch>
        <Route path='/signup'>
          <SignUpLayout>
            <SignUp />
          </SignUpLayout>
        </Route>

        <Redirect to='/signup' />
      </Switch>
    );
  }

  if (!!currentUser && !!currentUser.email_confirmed && !currentUser.company) {
    return (
      <Switch>
        <Route path="/company/new">
          <SignInLayout>
            <CreateCompany />
          </SignInLayout>
        </Route>

        <Route path="/company/join">
          <SignInLayout>
            <JoinCompany />
          </SignInLayout>
        </Route>

        <Redirect to='/company/new' />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/dashboard">
        <Switch>
          <DashboardLayout>
            <Route exact path="/dashboard/settings">
              <Profile />
            </Route>

            {
              TEMP_ADMIN_EMAILS.includes(currentUser.email) ? (
                <Route path="/dashboard/queue">
                  <Switch>
                    <Route path="/dashboard/queue/histories">
                      <AdminCronLogs dataType='histories' />
                    </Route>

                    <Route path="/dashboard/queue/activities">
                      <AdminCronLogs dataType='activities' />
                    </Route>
                  </Switch>
                </Route>
              ) : null
            }

            {
              TEMP_ADMIN_EMAILS.includes(currentUser.email) ? (
                <Route path="/">
                  <Switch>
                    <Route exact path="/dashboard/admin-users">
                      <AdminUsers />
                    </Route>

                    <Route path="/dashboard/admin-shipments">
                      <AdminShipments />
                    </Route>

                    <Route exact path="/dashboard/admin-containers">
                      <AdminContainers />
                    </Route>
                  </Switch>
                </Route>
              ) : null
            }

            {
              TEMP_ADMIN_EMAILS.includes(currentUser.email) ? (
                <Route exact path="/dashboard/containers-cache">
                  <AdminContainersCache />
                </Route>
              ) : null
            }

            <Route exact path="/dashboard/shipments">
              <ShipmentLayout hideTopHeader={true}>
                <AllShipments />
              </ShipmentLayout>
            </Route>

            <Route exact path="/dashboard/channel">
              <ChannelContainer />
            </Route>

            <Route exact path="/dashboard/collaborators">
              <CollaboratorsList />
            </Route>

            <Route path="/dashboard/shipments/:id">
              <Switch>
                <Route exact path="/dashboard/shipments/:id/overview">
                  <ShipmentLayout>
                    <ShipmentOverview />
                  </ShipmentLayout>
                </Route>

                <Route exact path="/dashboard/shipments/:id/files">
                  <ShipmentLayout>
                    <ShipmentFiles />
                  </ShipmentLayout>
                </Route>

                <Route exact path="/dashboard/shipments/:id">
                  <ShipmentLayout>
                    <ShipmentContainers />
                  </ShipmentLayout>
                </Route>

                <Redirect to='/dashboard/shipments' />
              </Switch>
            </Route>

            <Route exact path="/dashboard/vessel-schedules">
              <VesselSchedule />
            </Route>
          </DashboardLayout>
        </Switch>
      </Route>

      <Redirect to='/dashboard/shipments' />
    </Switch>
  );
};

export default inject("authStore")(observer(App));
