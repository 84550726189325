import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import sortBy from 'lodash.sortby';

import AccountInfo from '../../components/AccountInfo';
import TeamInfo from '../../components/TeamInfo';
import CompanyProfileInfo from '../../components/CompanyProfileInfo';
import CompanyInfo from '../../components/CompanyInfo';
import Avatar from '../../components/Avatar';

import './index.less';

const Profile = (props) => {
  const { currentUser } = props.authStore;
  const { getCompanyMembers, inviteMember, updateCompanyInfo, updateUserProfile, removeMember } = props.authStore;

  const [currentPanel, setCurrentPanel] = useState('Profile');
  const [isAdmin, setIsAdmin] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    loadCompanyMembers();
  }, []);

  const loadCompanyMembers = () => {
    getCompanyMembers()
      .then((data) => {
        setMembers(sortBy(data.list, 'type'));
        setIsAdmin(data.list.find(d => d.email == currentUser.email)?.type == 'COMPANY_ADMIN');
      });
  }

  const renderPanel = () => {
    switch (currentPanel) {
      case 'Profile': {
        return (
          <div style={{display: 'flex', flexDirection: 'column', gap: '48px'}}>
            <AccountInfo currentUser={currentUser} isAdmin={isAdmin} updateUserProfile={updateUserProfile} />
            <CompanyProfileInfo company={currentUser.company} isAdmin={isAdmin} updateCompanyInfo={updateCompanyInfo} />
            <TeamInfo members={members} isAdmin={isAdmin} inviteMember={inviteMember} removeMember={removeMember} reloadCompanyMembers={loadCompanyMembers} />
          </div>
        );
        break;
      }
      case 'company': {
        return (
          <CompanyInfo
            membershipInfo={ currentUser.companyMembership }
            registerCompany={ registerCompany }
            findCompany={ findCompany }
            joinCompany={ joinCompany }
            removeMember={ removeCompanyMember }
            approveMember={ approveCompanyMember }
          />
        );
        break;
      }
    }
  }

  return (
    <div className='settings-container'>
      <div className='settings-container__top-bar'>
        {currentPanel}
      </div>

      <div className='settings-container__body'>
        <div className='profile-info'>
          <div className='profile-info__left-bar'>
            <div className='profile-info__left-bar--avatar-block'>
              <Avatar firstName={ currentUser.first_name } lastName={ currentUser.last_name } email={ currentUser.email } size='large' />
              <div className='profile-info__left-bar--name'>{ currentUser.first_name } { currentUser.last_name }</div>
            </div>

            <div className='profile-info__left-bar--nav'>
              <div className={`profile-info__left-bar--nav-item ${ currentPanel == 'Profile' ? 'current' : '' }`} onClick={ () => setCurrentPanel('Profile') }>
                Profile & Users
              </div>

              {/*
              <div className={`profile-info__left-bar--nav-item ${ currentPanel == 'company' ? 'current' : '' }`} onClick={ () => setCurrentPanel('company') }>
                Company Info
              </div>
              */}
            </div>
          </div>

          <div className='profile-info__right-bar'>
            { renderPanel() }
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("authStore")(observer(Profile));
