import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Input, Table } from 'antd';
import debounce from 'lodash.debounce';

import ShipmentOverviewBlock from '../../components/ShipmentOverviewBlock';
import Avatar from '../../components/Avatar';

import './index.less';

const ALL_STATUS = {
  "none": "All",
  "Active": "Active",
  "Completed": "Completed",
};

const COLUMNS = [
  {
    title: 'Shipment #',
    dataIndex: 'title',
    key: 'title',
    width: 240
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 200,
    render: (content) => (
      <label className={content}>{ ALL_STATUS[content] }</label>
    )
  },
  {
    title: 'Containers',
    key: 'containers',
    dataIndex: 'containers',
    render: (containers) => {
      return (
        <div className='shipment-containers-status'>
          <div className='shipment-containers-status__block'>
            <div className='shipment-containers-status__block--icon onvessel'>
              <img src='/static/images/icons/onvessel.svg' />
            </div>

            <div className='shipment-containers-status__block--label'>
              On Vessel
            </div>

            <div className='shipment-containers-status__block--content'>
              {(containers || []).filter(c => c.status == 'OnVessel').length} CNTR
            </div>
          </div>

          <div className='shipment-containers-status__block center'>
            <div className='shipment-containers-status__block--icon arrived'>
              <img src='/static/images/icons/arrived.svg' />
            </div>

            <div className='shipment-containers-status__block--label'>
              Arrived
            </div>

            <div className='shipment-containers-status__block--content'>
              {(containers || []).filter(c => ['Arrived', 'Discharged'].includes(c.status)).length} CNTR
            </div>
          </div>

          <div className='shipment-containers-status__block'>
            <div className='shipment-containers-status__block--icon pickedup'>
              <img src='/static/images/icons/pickedup.svg' />
            </div>

            <div className='shipment-containers-status__block--label'>
              Picked Up
            </div>

            <div className='shipment-containers-status__block--content'>
              {(containers || []).filter(c => ['GateOut', 'EmptyReturned'].includes(c.status)).length} CNTR
            </div>
          </div>
        </div>
      );
    }
  },
  {
    title: 'Admin',
    key: 'admin',
    width: 160,
    render: (record) => {
      const admin = record.collaborators.find(c => c.role == 'Admin').user;
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar email={admin.email} firstName={admin.first_name} lastName={admin.last_name} />
          <span style={{ fontWeight: 400, color: '#000' }}>{ admin.first_name }</span>
        </div>
      );
    }
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (content) => {
      return (
        <div>
          <p>{ moment(content).format('MM/DD/YYYY') }</p>
          <p>{ moment(content).format('HH:mm:ss') }</p>
        </div>
      );
    }
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    width: 200,
    render: (content) => (
      <Link to={`/dashboard/shipments/${content}`}>View</Link>
    )
  },
];

const AllShipments = (props) => {
  const { setShowCreateModal } = props;
  const { isLoading, shipments, fetchAllShipments } = props.shipmentStore;

  const [currentFilter, setCurrentFilter] = useState('none');
  const selectFilter = (key) => {
    setCurrentFilter(key);
  }

  const [keywords, setKeywords] = useState();
  const search = (e) => {
    setKeywords(e.target.value);
  }

  var filterShipments = currentFilter == 'none' ? shipments : shipments.filter(s => s.status == currentFilter);
  if (!!keywords) {
    filterShipments = filterShipments.filter(s => s.title.toLowerCase().includes(keywords.toLowerCase()));
  }

  useEffect(() => {
    fetchAllShipments();
  }, []);

  return (
    <div className='all-shipments'>
      <div className='all-shipments__label'>
        All shipments
        <div className='all-shipments__label--create' onClick={ () => setShowCreateModal(true) }>
          <i className='fa-solid fa-plus' /> Create Shipment
        </div>
      </div>

      <div className='all-shipments__list'>
        <div className='all-shipments__list--searchbar'>
          <div className='all-shipments__list--searchbar-left'>
            <Input
              size='large'
              placeholder='search shipment'
              prefix={<i className='fa-solid fa-search' />}
              onChange={ debounce(search, 500) }
            />

            {
              Object.keys(ALL_STATUS).map((k) => (
                <label className={currentFilter == k ? 'current' : ''} key={k} onClick={ () => selectFilter(k) }>{ ALL_STATUS[k] }</label>
              ))
            }
          </div>

          <div className='all-shipments__list--searchbar-right'>
            <b>{ shipments.length }</b> Shipments
          </div>
        </div>

        <Table
          rowKey={(row) => row.id}
          className='all-shipments__table'
          columns={COLUMNS}
          dataSource={filterShipments}
          loading={isLoading}
        />
        {/*
          shipments.map((shipment) => (
            <Link to={`/dashboard/shipments/${shipment.id}`} key={shipment.id}>
              <div className='all-shipments__list-item'>
                <ShipmentOverviewBlock
                  shipment={shipment}
                />
              </div>
            </Link>
          ))
        */}
      </div>
    </div>
  );
};

export default inject("shipmentStore")(observer(AllShipments));
