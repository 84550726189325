import moment from 'moment';

export const upperCaseFirstLetter = (str) => {
  const firstLetter = str.slice(0,1);
  const resetLetter = str.slice(1);
  return `${firstLetter.toUpperCase()}${resetLetter}`;
};

export const formatedChatDateTime = (datetimeString, accurateForLongTime = true) => {
  if (!datetimeString) {
    return;
  }

  const datetime = moment(datetimeString);

  if (datetime.isAfter(moment().startOf('day'))) {
    return datetime.format('HH:mm');
  }

  if (datetime.isSameOrAfter(moment().subtract(1, 'day').startOf('day'))) {
    return `Yesterday ${datetime.format('HH:mm')}`;
  }

  if (datetime.isBefore(moment().subtract(1, 'day').startOf('day'))) {
    if (!accurateForLongTime) {
      return datetime.format('MMM DD, YYYY');
    }

    return datetime.format('MMM DD, YYYY HH:mm');
  }
}

export const getMessagePreview = (message) => {
  return (message?.data.root.children || []).map((c1) => (
    c1.children.map(c2 => (
      c2.text
    )).join('\n')
  )).join('\n');
}

export const formatDatetime = (datetime, portTimeZone, format = 'YYYY-MM-DD HH:mm(ddd)') => {
  // if (!datetime) {
  //   return 'N/A';
  // }

  // let offsetHours = '';
  // if (+portTimeZone < 0) {
  //   offsetHours = `${portTimeZone}:00`;
  // } else {
  //   offsetHours = `+${portTimeZone}:00`;
  // }

  // return moment(`${datetime.replace(/T/, ' ').replace(/-/g, '/')} ${offsetHours}`).format(format);

  if (!datetime) {
    return 'N/A';
  }

  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes} (${getWeekDay(date)})`;
}

const getWeekDay = (date) => {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayIndex = date.getDay();
  return weekdays[dayIndex];
}
