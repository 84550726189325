import React, { useEffect } from 'react';
import {$getRoot, $getSelection, $createTextNode, $createParagraphNode} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

function UpdateContentPlugin(props) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const newEditorState = editor.parseEditorState(JSON.stringify(props.content));
    editor.setEditorState(newEditorState);
    editor.focus();
  }, [editor, props.updateTimestamp]);

  return null;
}

export default UpdateContentPlugin;
