import React, { useCallback, useEffect, useState } from 'react';
import { Table, Select, Button, Input, DatePicker } from 'antd';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import debounce from 'lodash.debounce';

import rpgRequest from '../../lib/rpgRequest.js';

import './index.less';

const { RangePicker } = DatePicker;

const DESTINATIONS = {
  TERMINAL: ['LBCT', 'ITS', 'ETS', 'YTI', 'FMS', 'APMT', 'WBCT', 'PCT', 'TTI', 'TRAPAC'],
  CARRIER: ['MATS', 'EGLV', 'MAEU', 'MSCU', 'COSU', 'CULU', 'ONEY', 'SMLM', 'YMLU', 'HDMU', 'TraPac', 'TRAPAC', 'OOLU', 'CMDU']
};

const AdminCronLogs = (props) => {
  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'start_at',
      render: (content) => {
        return moment(content).format('MM/DD/YYYY HH:mm');
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (content, record) => (
        <span className={`cron-job-logs__log-status ${content}`}>
          {
            ['ERROR', 'SKIP', 'MAX-TRY-FAIL'].includes(content) ? (
              <b>{ content }</b>
            ) : (
              content
            )
          }

          {
            record.status == 'FINISH' && isEmpty(record.formatedOutput) ? <span style={{color: 'red'}}> EMPTY</span> : null
          }
        </span>
      )
    },
    {
      title: 'Cntrs or Bol',
      key: 'cntrs',
      render: (record) => {
        return (
          <div>
            {
              record.cntrs.join(', ') ? (
                <p>CNTR: { record.cntrs.join(', ') }</p>
              ) : null
            }
            {
              record.bol ? (
                <p>BOL: { record.bol }</p>
              ) : null
            }
          </div>
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Data Type',
      dataIndex: 'catchType',
      key: 'catchType',
    },
    {
      title: 'Scraping Destination',
      key: 'scrapingDestination',
      render: (record) => {
        if (record.catchType == 'CARRIER') {
          return record.scac;
        }

        return record.terminalCode || (record.additionalParams?.tryTerminal ? `${record.additionalParams.tryTerminal} - POLLING` : 'FIRST CREATED - POLLING');
      }
    },
    {
      title: 'Try Count',
      dataIndex: 'tryCount',
      key: 'tryCount',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
  ];

  const { dataType } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState({ page: 1 });

  const paginate = (page) => {
    setQuery({
      ...query,
      page
    });
  }

  const queryLogs = () => {
    let queryParams = query;

    if (query.catchType == 'CARRIER') {
      queryParams.scac = query.destination;
      queryParams.terminalCode = undefined;
    }

    if (query.catchType == 'TERMINAL') {
      queryParams.terminalCode = query.destination;
      queryParams.scac = undefined;
    }

    startQuery(dataType, queryParams);
  }

  const startQuery = useCallback(
    debounce((dataType, queryParams) => {
      setIsLoading(true);
      rpgRequest(`/api/admin/queue_tasks/${dataType}`, 'post', { query: queryParams })
        .then((res) => {
          setIsLoading(false);
          setData(res.data.list);
          setTotal(res.data.count);
        })
        .catch((err) => {
          setIsLoading(false);
          message.error('The 3rd part service is invalid.', 5);
        });
    }, 500)
  , []);

  const setRange = ([start, end]) => {
    setQuery({
      ...query,
      createdAt: [start.valueOf(), end.valueOf()]
    });
  }

  const setFilter = (key) => (value) => {
    if (['bol'].includes(key)) {
      value = value.target.value;
    }

    let sideEffectParams = {};

    if (key == 'catchType') {
      sideEffectParams = {
        destination: undefined
      };
    }

    if (value == 'NONE') {
      setQuery({
        ...query,
        page: 1,
        [key]: undefined,
        ...sideEffectParams
      });
      return;
    }

    setQuery({
      ...query,
      page: 1,
      [key]: value,
      ...sideEffectParams
    });
  }

  useEffect(() => {
    setData([]);
    setTotal(0);

    queryLogs();
  }, [dataType, query]);

  return (
    <div className='cron-job-logs'>
      <div className='cron-job-logs__tool-bar'>
        <div className='cron-job-logs__tool-bar--filter'>
          <RangePicker
            showTime
            value={(query.createdAt || []).map(t => moment(t))}
            onChange={setRange}
          />
        </div>

        <div className='cron-job-logs__tool-bar--filter'>
          <Select
            placeholder='Status'
            style={{ width: 160 }}
            options={[
              { label: 'ERROR', value: 'ERROR' },
              { label: 'SKIP', value: 'SKIP' },
              { label: 'FINISH', value: 'FINISH' },
              { label: 'MAX-TRY-FAIL', value: 'MAX-TRY-FAIL' }
            ]}
            value={ query.status }
            onChange={ setFilter('status') }
          />
        </div>

        <div className='cron-job-logs__tool-bar--filter'>
          <Input
            placeholder='Bol/Cntr'
            style={{ width: 160 }}
            value={ query.bol }
            onChange={ setFilter('bol') }
          />
        </div>

        <div className='cron-job-logs__tool-bar--filter'>
          <Select
            placeholder='Type'
            style={{ width: 160 }}
            options={[
              { label: 'NONE', value: 'NONE' },
              { label: 'SCHEDULED', value: 'SCHEDULED' },
              { label: 'USER-ADDED', value: 'USER-ADDED' }
            ]}
            value={ query.type }
            onChange={ setFilter('type') }
          />
        </div>

        <div className='cron-job-logs__tool-bar--filter'>
          <Select
            placeholder='Data Type'
            style={{ width: 120 }}
            options={[
              { label: 'NONE', value: 'NONE' },
              { label: 'TERMINAL', value: 'TERMINAL' },
              { label: 'CARRIER', value: 'CARRIER' }
            ]}
            value={ query.catchType }
            onChange={ setFilter('catchType') }
          />

          <Select
            placeholder='Destination'
            style={{ width: 120 }}
            options={(DESTINATIONS[query.catchType] || []).map(d => ({ label: d, value: d }))}
            value={ query.destination }
            onChange={ setFilter('destination') }
          />
        </div>

        <div className='cron-job-logs__tool-bar--filter'>
          <Button onClick={ () => setQuery({}) }>Reset</Button>
        </div>
      </div>

      <div className='cron-job-logs__status-bar'>
        <div className='cron-job-logs__status-bar--block'>
          <label>Current Page:</label>
        </div>

        <div className='cron-job-logs__status-bar--block'>
          <label>SKIP</label>
          <span data-count={data.filter(d => d.status == 'SKIP').length} />
        </div>

        <div className='cron-job-logs__status-bar--block'>
          <label>ERROR</label>
          <span data-count={data.filter(d => d.status == 'ERROR').length} />
        </div>

        <div className='cron-job-logs__status-bar--block'>
          <label>MAX-TRY-FAIL</label>
          <span data-count={data.filter(d => d.status == 'MAX-TRY-FAIL').length} />
        </div>

        <div className='cron-job-logs__status-bar--block'>
          <label>EMPTY DATA</label>
          <span data-count={data.filter(d => d.status == 'FINISH' && isEmpty(d.formatedOutput)).length} />
        </div>
      </div>

      <Table
        rowKey={(record) => `${record._id}-${record.tryCount}`}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={{
          pageSize: 20,
          total: total,
          current: query.page,
          onChange: paginate,
          showSizeChanger: false
        }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <div className='cron-job-logs__detail_panel'>
              { JSON.stringify(record, null, 8) }
            </div>
          )
        }}
      />
    </div>
  );
}

export default AdminCronLogs;
