import lexical from 'lexical';
import LexicalComposerContext from '@lexical/react/LexicalComposerContext';
import utils from '@lexical/utils';
import React, { useEffect } from 'react';

function KeyPressPlugin({onKeyDown, content}) {
  const [editor] = LexicalComposerContext.useLexicalComposerContext();

  useEffect(() => {
    return utils.mergeRegister(editor.registerCommand(lexical.KEY_ENTER_COMMAND, event => {
      onKeyDown(event);
      return true;
    }, lexical.COMMAND_PRIORITY_LOW));
  }, [editor, content]);

  return null;
}

export default KeyPressPlugin;
