import React, { useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import { Select, message } from 'antd';

import './index.less';

const CollaboratorInvitationLink = (props) => {
  const { shipment, role, invite, disableConfirm } = props;

  const shareLink = () => {
    copy(`${window.location.origin}/shipments/${shipment.id}/invitation/${role}`);
    message.success('Copied URL.');
  }

  return (
    <div className='collaborator-invitation-link'>
      <div className='collaborator-invitation-link__link' onClick={shareLink}>
        <i className='fa-solid fa-link' /> Copy link
      </div>

      <div className='collaborator-invitation-link__save' disabled={ disableConfirm } onClick={invite}>
        Invite
      </div>
    </div>
  );
};

CollaboratorInvitationLink.propTypes = {
  shipment: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  invite: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool.isRequired,
};

export default CollaboratorInvitationLink;
