import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { matchPath } from 'react-router';
import { Link } from 'react-router-dom';

import Avatar from '../../components/Avatar';

import { TEMP_ADMIN_EMAILS } from '../../utils/constants';

import webSocketClient from '../../lib/webSocketClient.js';
webSocketClient.init();

import { Dropdown, Menu } from 'antd';

import './index.less';

const Layout = (props) => {
  const { socket, init, calculateMessagesCount } = props.channelStore;
  useEffect(() => {
    if (!!socket) {
      init();
    }
  }, [socket]);

  useEffect(() => {
    const isMatch = isMatchedSubPath('queue');
    if (isMatch) {
      setGroupMenuExpand(true);
    }
  }, [props.routing.location?.pathname]);

  useEffect(() => {
    if (props.authStore.currentUser?.id) {
      props.collaboratorStore.fetchAllCollaboratorRelations();
    }
  }, [props.authStore.currentUser]);

  const [groupMenuExpand, setGroupMenuExpand] = useState(false);
  const toggleGroupMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setGroupMenuExpand(!groupMenuExpand);
  }

  const menu = (
    <Menu
      items={[
        {
          key: 'profile',
          label: (
            <Link to='/dashboard/settings'>Account Settings</Link>
          )
        },
        {
          key: 'signout',
          label: (
            <span onClick={ props.authStore.signOut }>Sign out</span>
          )
        }
      ]}
    />
  );

  const isMatchedSubPath = (subPath) => {
    const res = matchPath(props.routing.location?.pathname, {
      path: `/dashboard/${subPath}`,
      exact: false,
      strict: false
    });
    return !!res;
  }

  const unreadMessagesCount = calculateMessagesCount(props.authStore.currentUser.id)()('Unread');

  return (
    <div className='dashboard-layout'>
      <div className='dashboard-layout__sidebar'>
        <div className='dashboard-layout__sidebar-top'>
          <div className='dashboard-layout__logo'>
            <img src='/static/images/logo-with-color.svg' />
          </div>

          <div className='dashboard-layout__nav-block'>
            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('shipments') ? 'current' : ''}`} to='/dashboard/shipments'>
              <i className="fas fa-box" />
              <span>Shipment</span>
            </Link>

            {/*
            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('schedule') ? 'current' : ''}`} to='/dashboard/schedule'>
              <i className="fas fa-calendar-check" />
              <span>Schedule</span>
            </Link>
            */}

            {/*
            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('view') ? 'current' : ''}`} to='/dashboard/view'>
              <i className="fas fa-stream" />
              <span>View</span>
            </Link>
            */}

            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('channel') ? 'current' : ''}`} to='/dashboard/channel'>
              <i className="fas fa-bolt" />
              <span>Channel</span>
              {
                !!unreadMessagesCount ? (
                  <label className='unread'>{ unreadMessagesCount }</label>
                ) : null
              }
            </Link>

            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('collaborators') ? 'current' : ''}`} to='/dashboard/collaborators'>
              <i className="fas fa-user-friends" />
              <span>Collaborators</span>
            </Link>

            {/*
            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('vessel-schedules') ? 'current' : ''}`} to='/dashboard/vessel-schedules'>
              <i className="fa-solid fa-calendar-days" />
              <span>Vessel Schedule</span>
            </Link>
            */}

            {/*
            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('files') ? 'current' : ''}`} to='/dashboard/files'>
              <i className="fas fa-folder-open" />
              <span>Files</span>
            </Link>
            */}

            {/*
            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('appointments') ? 'current' : ''} ${groupMenuExpand ? 'with-sub-menu' : ''}`} to='/dashboard/appointments' onClick={toggleGroupMenu}>
              <i className="far fa-calendar-alt" />
              <span>Appointments</span>
              {
                !!groupMenuExpand ? (
                  <i className="fa-solid fa-chevron-down" style={{verticalAlign: 'text-bottom', marginLeft: '12px'}} />
                ) : (
                  <i className="fa-solid fa-chevron-right" style={{verticalAlign: 'text-bottom', marginLeft: '12px'}} />
                )
              }
            </Link>

            {
              !!groupMenuExpand ? (
                <>
                  <Link className={`dashboard-layout__nav-block--item sub-menu ${ isMatchedSubPath('appointments/terminal') || isMatchedSubPath('appointments/watch-list') ? 'current' : ''}`} to='/dashboard/appointments/terminal'>
                    <span>Terminal</span>
                  </Link>

                  <Link className={`dashboard-layout__nav-block--item sub-menu last-sub-menu ${ isMatchedSubPath('appointments/delivery') ? 'current' : ''}`} to='/dashboard/appointments/delivery'>
                    <span>Delivery</span>
                  </Link>
                </>
              ) : null
            }
            */}
          </div>

          <div className='dashboard-layout__nav-block'>
            {
              TEMP_ADMIN_EMAILS.includes(props.authStore.currentUser.email) ? (
                <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('admin-users') ? 'current' : ''}`} to='/dashboard/admin-users'>
                  <i className="fa-solid fa-calendar-days" />
                  <span>All Users</span>
                </Link>
              ) : null
            }

            {
              TEMP_ADMIN_EMAILS.includes(props.authStore.currentUser.email) ? (
                <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('admin-shipments') ? 'current' : ''}`} to='/dashboard/admin-shipments'>
                  <i className="fa-solid fa-calendar-days" />
                  <span>All Shipments</span>
                </Link>
              ) : null
            }

            {
              TEMP_ADMIN_EMAILS.includes(props.authStore.currentUser.email) ? (
                <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('admin-containers') ? 'current' : ''}`} to='/dashboard/admin-containers'>
                  <i className="fa-solid fa-calendar-days" />
                  <span>All Containers</span>
                </Link>
              ) : null
            }

            {
              TEMP_ADMIN_EMAILS.includes(props.authStore.currentUser.email) ? (
                <>
                  <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('queue') ? 'current' : ''} ${groupMenuExpand ? 'with-sub-menu' : ''}`} to='/dashboard/queue' onClick={toggleGroupMenu}>
                    <i className="fa-solid fa-calendar-days" />
                    <span>Queue</span>
                    {
                      !!groupMenuExpand ? (
                        <i className="fa-solid fa-chevron-down" style={{verticalAlign: 'text-bottom', marginLeft: '12px'}} />
                      ) : (
                        <i className="fa-solid fa-chevron-right" style={{verticalAlign: 'text-bottom', marginLeft: '12px'}} />
                      )
                    }
                  </Link>

                  {
                    !!groupMenuExpand ? (
                      <>
                        <Link className={`dashboard-layout__nav-block--item sub-menu ${ isMatchedSubPath('queue/histories') ? 'current' : ''}`} to='/dashboard/queue/histories'>
                          <span>Histories</span>
                        </Link>

                        <Link className={`dashboard-layout__nav-block--item sub-menu last-sub-menu ${ isMatchedSubPath('queue/activities') ? 'current' : ''}`} to='/dashboard/queue/activities'>
                          <span>Activities</span>
                        </Link>
                      </>
                    ) : null
                  }
                </>
              ) : null
            }

            {
              TEMP_ADMIN_EMAILS.includes(props.authStore.currentUser.email) ? (
                <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('containers-cache') ? 'current' : ''}`} to='/dashboard/containers-cache'>
                  <i className="fa-solid fa-calendar-days" />
                  <span>Container Cache</span>
                </Link>
              ) : null
            }

            <Link className={`dashboard-layout__nav-block--item ${ isMatchedSubPath('settings') ? 'current' : ''}`} to='/dashboard/settings'>
              <i className='fa-solid fa-gear' />
              <span>Settings</span>
            </Link>
          </div>
        </div>

        <div className='dashboard-layout__sidebar-bottom'>
          <Dropdown
            overlay={menu}
            placement='topLeft'
          >
            <div className='dashboard-layout__avatar-block'>
              <div className='dashboard-layout__avatar-block--left'>
                <Avatar firstName={props.authStore.currentUser.first_name} lastName={props.authStore.currentUser.last_name} email={props.authStore.currentUser.email} />
                <div className='dashboard-layout__avatar-block--name'>{ props.authStore.currentUser.first_name }</div>
              </div>

              <div className='dashboard-layout__avatar-block--right'>
                <i className='fa-solid fa-angle-down' />
              </div>
            </div>
          </Dropdown>

        </div>
      </div>

      <div className='dashboard-layout__body'>
        { props.children }
      </div>
    </div>
  );
}

export default inject('routing', 'authStore', 'channelStore', 'collaboratorStore')(observer(Layout));
