import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Divider, Menu } from 'antd';

import { UserRoleOnShipment } from '../../utils/constants.js';
import Avatar from '../Avatar';

import './index.less';

const CollaboratorBlock = (props) => {
  const { isAdmin, relationship, onlyShowInfo, updateCollaboratorRole, removeCollaborator } = props;

  const updateRelationshipRole = (e) => {
    const { shipmentId, userId } = relationship;
    updateCollaboratorRole(shipmentId, userId, e.key);
  }

  const removeRelationship = () => {
    const { shipmentId, userId } = relationship;
    removeCollaborator(shipmentId, userId);
  }

  const menu = () => {
    let items = Object.keys(UserRoleOnShipment).filter(k => k != "Admin").map((k) => ({
      key: UserRoleOnShipment[k].value,
      label: UserRoleOnShipment[k].label,
      onClick: updateRelationshipRole
    }));

    if (!!isAdmin) {
      items = items.concat([
        {
          type: 'divider',
        },
        {
          key: 'REMOVE',
          label: 'Remove member',
          onClick: removeRelationship
        }
      ]);
    }

    return (
      <Menu
        items={items}
      />
    );
  };

  return (
    <div className='collaborator-block'>
      <div className='collaborator-block__info'>
        <Avatar firstName={ relationship.user.first_name } lastName={ relationship.user.last_name } email={ relationship.user.email } size='w-32' />
        <div className='collaborator-block__info--content'>
          <div className='collaborator-block__info--name'>{ relationship.user.first_name }</div>
          <div className='collaborator-block__info--email'>{ relationship.user.email }</div>
        </div>
      </div>

      {
        !!onlyShowInfo ? (
          null
        ) : (
          relationship.role != 'Admin' ? (
            <Dropdown overlay={menu()} trigger={['click']}>
              <div className='collaborator-block__setting' style={{cursor: "pointer"}}>
                { UserRoleOnShipment[relationship.role].label }
                <i className='fa-solid fa-angle-down' />
              </div>
            </Dropdown>
          ) : (
            <div className='collaborator-block__setting'>
              { UserRoleOnShipment[relationship.role].label }
            </div>
          )
        )
      }
    </div>
  );
}

CollaboratorBlock.propTypes = {
  relationship: PropTypes.object.isRequired,
  updateCollaboratorRole: PropTypes.func,
  removeCollaborator: PropTypes.func,
  isAdmin: PropTypes.bool,
  onlyShowInfo: PropTypes.bool,
};

export default CollaboratorBlock;
