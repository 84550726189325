import React, { useEffect, useState } from 'react';
import { Input, Table, DatePicker } from 'antd';
import debounce from 'lodash.debounce';
import moment from 'moment';
import rpgRequest from '../../lib/rpgRequest.js';

const { RangePicker } = DatePicker;

import './index.less';

const VesselSchedule = (props) => {
  const columns = [
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
    },
    {
      title: 'Vessel Code',
      dataIndex: 'vessel_abbrev',
      key: 'vessel_abbrev',
    },
    {
      title: 'Voyage',
      dataIndex: 'voyage',
      key: 'voyage',
    },
    {
      title: 'Terminal Code',
      dataIndex: 'terminal_code',
      key: 'terminal_code',
    },
    {
      title: 'ETA',
      dataIndex: 'eta',
      key: 'eta',
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      key: 'etd',
    },
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [keywords, setKeywords] = useState('');
  const setSearchKeywords = (e) => {
    setKeywords(e.target.value.toLowerCase());
  };

  const [filterTerminalCode, setFilterTerminalCode] = useState('');
  const setTerminalCode = (e) => {
    setFilterTerminalCode(e.target.value.toLowerCase());
  };

  const [etaRange, setEtaRange] = useState();

  const [data, setData] = useState([]);

  useEffect(() => {
    rpgRequest('/api/vessel_schedules')
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error('The 3rd part service is invalid.', 5);
      });
  }, []);

  return (
    <div className='vessel-schedule'>
      <div className='vessel-schedule__search-bar'>
        <Input
          className='vessel-schedule__search-bar--input middle'
          size='large'
          width={300}
          placeholder='Input the owner, vessel name or voyage'
          onChange={debounce(setSearchKeywords, 300)}
        />

        <Input
          className='vessel-schedule__search-bar--input small'
          size='large'
          width={300}
          placeholder='Input the terminal code'
          onChange={debounce(setTerminalCode, 300)}
        />

        <RangePicker
          size='large'
          placeholder={['ETA start', 'ETA end']}
          onChange={setEtaRange}
        />
      </div>

      <Table
        rowKey='id'
        dataSource={
          data.filter((r) => {
            return (r.owner.toLowerCase().includes(keywords) || r.vessel_name.toLowerCase().includes(keywords) || r.vessel_abbrev.toLowerCase().includes(keywords) || (r.voyage || '').toLowerCase().includes(keywords)) && r.terminal_code.toLowerCase().includes(filterTerminalCode);
          })
            .filter((r) => {
              if (!etaRange) {
                return true;
              }

              if (!r.eta) {
                return false;
              }

              const eta = moment(r.eta).valueOf();
              return eta >= etaRange[0].valueOf() && eta <= etaRange[1].valueOf();
            })
        }
        columns={columns}
        loading={isLoading}
      />
    </div>
  );
}
export default VesselSchedule;
