import React from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import { Form, Input, Button } from 'antd';

import './index.less';

const CreateCompany = (props) => {
  const { authStore } = props;

  const [form] = Form.useForm();

  const registerCompany = (data) => {
    authStore.registerCompany(data)
  }

  return (
    <div className='create-company'>
      <div className='signin-layout__title'>Create your Company</div>

      <Form
        form={form}
        className='signin-layout__form'
        layout="vertical"
        size='large'
        onFinish={ registerCompany }
      >
        <Form.Item
          name='name'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Company Name</span>}
          rules={[{ required: true }]}
        >
          <Input placeholder='Company Name' />
        </Form.Item>

        {/*
        <Form.Item
          name='type'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Company Type</span>}
          rules={[{ required: true }]}
        >
          <Input placeholder='Company Type' />
        </Form.Item>
        */}

        <Form.Item
          name='scac'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>SCAC Code</span>}
        >
          <Input placeholder='SCAC Code' />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' className='signin-layout__submit-button'>
            Create Now
          </Button>
        </Form.Item>
      </Form>

      <div className='signin-layout__text-info'>
        <Link to='/company/join'>or join a compnay</Link>
      </div>
    </div>
  );
};

export default inject('authStore')(observer(CreateCompany));
