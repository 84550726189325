import React from 'react';
import { inject, observer } from "mobx-react";
import { matchPath } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import './index.less';

const AppointmentsLayout = (props) => {

  const isMatchedSubPath = (subPath) => {
    const res = matchPath(props.routing.location?.pathname, {
      path: `/dashboard/appointments/${subPath}`,
      exact: true,
      strict: false
    });
    return !!res;
  }

  return (
    <div className='appointments-layout'>
      <div className='appointments-layout__nav'>
        <a className='appointments-layout__nav--back-btn'>
          <i className='fa-solid fa-calendar' />
        </a>

        <div className='appointments-layout__nav--info'>
          <i className='fa-solid fa-angle-right' />
          <span>Ternimal appointment</span>
        </div>

        <div className='appointments-layout__nav--navbar'>
          <div className='appointments-layout__nav--left-navs'>
            <Link className={`appointments-layout__nav--left-nav-item ${isMatchedSubPath('terminal') ? 'current' : ''}`} to={`/dashboard/appointments/terminal`}>
              My Appointments
            </Link>

            <Link className={`appointments-layout__nav--left-nav-item ${isMatchedSubPath('watch-list') ? 'current' : ''}`} to={`/dashboard/appointments/watch-list`}>
              Watch List
            </Link>
          </div>
        </div>
      </div>

      <div className='appointments-layout__body'>
        { props.children }
      </div>
    </div>
  );
};

export default inject("routing")(observer(AppointmentsLayout));
