import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, DatePicker, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

import './index.less';

const AppointmentInfoModal = (props) => {
  const { visible, cancel, containerId, appointment, createPickUpAppointment, updatePickUpAppointment } = props;

  useEffect(() => {
    if (appointment?.pickUpAt) {
      setPickUpAt(appointment.pickUpAt);
    }

    if (appointment?.files?.length > 0) {
      setFiles(appointment.files);
    }
  }, [appointment]);

  const [pickUpAt, setPickUpAt] = useState(moment().toISOString());
  const [files, setFiles] = useState([]);
  const setUploadingFiles = (files) => {
    setFiles(files);
  }

  const onConfirm = () => {
    const uploadingFiles = files.map((file) => {
      const splitFileName = file.name.split('.');
      const extension = splitFileName.pop();
      const name = splitFileName.join('.');

      return {
        content: file.originFileObj,
        extension,
        name,
        id: file.id,
      };
    });

    if (appointment?.key) {
      updatePickUpAppointment(appointment, pickUpAt, uploadingFiles, () => {
        cancel();
      });
    } else {
      createPickUpAppointment(containerId, pickUpAt, uploadingFiles, () => {
        cancel();
      });
    }
  }

  return (
    <Modal
      className='appoinment-info-modal'
      open={visible}
      title='Appointment info'
      okText='Confirm'
      onCancel={cancel}
      onOk={onConfirm}
    >
      <div className='appointment-info-modal__block'>
        <div className='appointment-info-modal__block--label'>Pick up Time*</div>
        <DatePicker
          className='appointment-info-modal__block--input'
          format={"YYYY-MM-DD HH:mm"}
          size='large'
          showTime={{ format: 'HH:mm' }}
          value={moment(pickUpAt)}
          onChange={m => setPickUpAt(m.toISOString())}
        />
      </div>

      <div className='appointment-info-modal__block'>
        <div className='appointment-info-modal__block--label'>Files</div>
        <Upload
          multiple={true}
          onChange={({fileList}) => setUploadingFiles(fileList)}
          fileList={files.map((f) => ({ ...f, status: 'done', url: '/' }))}
          customRequest={() => {}}
          previewFile={null}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
    </Modal>
  );
};

AppointmentInfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  containerId: PropTypes.string,
  appointment: PropTypes.object,
  createPickUpAppointment: PropTypes.func,
  updatePickUpAppointment: PropTypes.func,
};

export default AppointmentInfoModal;
