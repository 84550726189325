import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Menu } from 'antd';

import JoinCompanyModal from '../JoinCompanyModal';
import CompanyForm from '../CompanyForm';

import './index.less';

const CompanyInfo = (props) => {
  const { membershipInfo, registerCompany, findCompany, joinCompany, approveMember, removeMember } = props;

  const [wayToCreateCompany, setWayToCreateCompany] = useState(null);

  const onRegisterCompany = async (data) => {
    registerCompany(data)
      .then(() => {
        setWayToCreateCompany(null);
      });
  }

  const menu = (membership) => {
    let items = [];
    if (membership.review_status == 'Pending') {
      items.push({
        key: 'approve',
        label: (
          <span onClick={ () => approveMember(membership.user.id) }>Approve</span>
        )
      });
    }

    return (
      <Menu
        items={[
          ...items,
          {
            key: 'remove',
            label: (
              <span onClick={ () => removeMember(membership.user.id) }>Remove</span>
            )
          }
        ]}
      />
    );
  }

  if (!membershipInfo?.company) {
    if (wayToCreateCompany == 'register') {
      return (
        <div className='company-info with-margin'>
          <div className='company-info__header'>Company registration</div>

          <CompanyForm
            onSubmit={ onRegisterCompany }
            onCancel={ () => setWayToCreateCompany(null) }
          />
        </div>
      );
    }

    return (
      <div className='company-info'>
        <div className='company-info__placeholder'>
          <div className='company-info__placeholder--text'>
            You don't belong to a company yet.
          </div>

          <div className='company-info__placeholder--btns'>
            <div className='company-info__placeholder--btn block' onClick={ () => setWayToCreateCompany('register') }>
              New Company Registration
            </div>

            <span>or</span>

            <div className='company-info__placeholder--btn border' onClick={ () => setWayToCreateCompany('join') }>
              Join a Company
            </div>
          </div>
        </div>

        <JoinCompanyModal
          visible={ wayToCreateCompany == 'join' }
          onCancel={ setWayToCreateCompany }
          findCompany={ findCompany }
          joinCompany={ joinCompany }
        />
      </div>
    );
  }

  return (
    <div className='company-info'>
      <div className='company-info__basic'>
        <div className='company-info__basic--label'>
          Company Information
          {
            membershipInfo.review_status == 'Pending' ? (
              <span className='pending'>Pending review</span>
            ) : null
          }
        </div>

        <div className='company-info__basic--main'>
          <div className='company-info__basic--column-block'>
            <div className='company-info__basic--column-label'>
              *Company Name
            </div>
            <div className='company-info__basic--column-content'>
              { membershipInfo.company.name }
            </div>
          </div>

          <div className='company-info__basic--column-block'>
            <div className='company-info__basic--column-label'>
              *Company Code
            </div>
            <div className='company-info__basic--column-content'>
              { membershipInfo.company.company_code }
            </div>
          </div>

          <div className='company-info__basic--column-block'>
            <div className='company-info__basic--column-label'>
              *Company Type
            </div>
            <div className='company-info__basic--column-content'>
              { membershipInfo.company.type }
            </div>
          </div>

          <div className='company-info__basic--column-block inline'>
            <div className='company-info__basic--inline-column'>
              <div className='company-info__basic--column-label'>
                *Phone Number
              </div>
              <div className='company-info__basic--column-content'>
                { membershipInfo.company.phone_number }
              </div>
            </div>

            <div className='company-info__basic--inline-column'>
              <div className='company-info__basic--column-label'>
                *Fax Number
              </div>
              <div className='company-info__basic--column-content'>
                { membershipInfo.company.fax_number }
              </div>
            </div>
          </div>

          <div className='company-info__basic--column-block inline'>
            <div className='company-info__basic--inline-column'>
              <div className='company-info__basic--column-label'>
                SCAC Code
              </div>
              <div className='company-info__basic--column-content'>
                { membershipInfo.company.scac_code }
              </div>
            </div>

            <div className='company-info__basic--inline-column'>
              <div className='company-info__basic--column-label'>
                US DOT Permit No
              </div>
              <div className='company-info__basic--column-content'>
                { membershipInfo.company.us_dot_permit_no }
              </div>
            </div>
          </div>

          <div className='company-info__basic--column-block'>
            <div className='company-info__basic--column-label'>
              Motor Carrier Permit No
            </div>
            <div className='company-info__basic--column-content'>
              { membershipInfo.company.motor_carrier_permit_no }
            </div>
          </div>

          <div className='company-info__basic--column-block'>
            <div className='company-info__basic--column-label'>
              *Detailed address
            </div>
            <div className='company-info__basic--column-content'>
              { membershipInfo.company.detailed_address }
            </div>
          </div>

          <div className='company-info__basic--column-block'>
            <div className='company-info__basic--column-label'>
              *Company Description
            </div>
            <div className='company-info__basic--column-content large'>
              { membershipInfo.company.company_description }
            </div>
          </div>
        </div>
      </div>

      <div className='company-info__members'>
        <div className='company-info__members--label'>Company Members</div>

        <div className='company-info__members--content'>
          <table>
            <thead>
              <tr>
                <td>User</td>
                <td>Phone</td>
                <td>Email</td>
                <td>Company role</td>
                <td>Review status</td>
                {
                  membershipInfo.role == 'Admin' ? (
                    <td>Actions</td>
                  ) : null
                }
              </tr>
            </thead>

            <tbody>
              {
                membershipInfo.company.companyMemberships.map((ms) => (
                  <tr key={ms.id}>
                    <td>{ms.user.first_name}</td>
                    <td>{ms.user.phone_number}</td>
                    <td>{ms.user.email}</td>
                    <td>{ms.role}</td>
                    <td className={ms.review_status == 'Pending' ? 'warning' : ''}>{ms.review_status}</td>
                    {
                      membershipInfo.role == 'Admin' ? (
                        <td>
                          {
                            ms.role == 'Admin' ? (
                              null
                            ) : (
                              <Dropdown
                                overlay={menu(ms)}
                              >
                                <i className='fas fa-ellipsis-h' />
                              </Dropdown>
                            )
                          }
                        </td>
                      ) : null
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  membershipInfo: PropTypes.object,
  registerCompany: PropTypes.func.isRequired,
  findCompany: PropTypes.func.isRequired,
  joinCompany: PropTypes.func.isRequired,
};

export default CompanyInfo;
