import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'lodash.debounce';
import uniq from 'lodash.uniq';
import { Link, useLocation } from "react-router-dom";

import { Table, Input, Tag, message } from 'antd';
import rpgRequest from '../../lib/rpgRequest.js';

import Avatar from '../../components/Avatar';

import './index.less';

const ALL_STATUS = {
  "none": "All",
  "OnVessel": "On Vessel",
  "Arrived": "Arrived",
  "Completed": "Completed",
};

const AdminShipments = (props) => {
  //# Force trigger refresh after query params changed.
  useLocation();

  const COLUMNS = [
    {
      title: 'Shipment #',
      dataIndex: 'title',
      key: 'title',
      width: 240
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (content) => (
        <label className={content}>{ ALL_STATUS[content] }</label>
      )
    },
    {
      title: 'Admin',
      key: 'admin',
      width: 160,
      render: (record) => {
        const admin = record.collaborators.find(c => c.role == 'Admin').user;
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', color: '#1890ff' }} onClick={() => props.routing.replace(`${props.routing.location.pathname}?collaborator=${admin.email}`)}>
            <Avatar email={admin.email} firstName={admin.first_name} lastName={admin.last_name} />
            <span style={{ fontWeight: 400 }}>{ admin.first_name }</span>
          </div>
        );
      }
    },
    {
      title: 'Containers',
      key: 'containers',
      render: (record) => {
        return <Link to={`/dashboard/admin-containers?shipment=${record.id}`}>{ record.containers.length }</Link>;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (content) => {
        return (
          <div>
            <p>{ moment(content).format('MM/DD/YYYY') }</p>
            <p>{ moment(content).format('HH:mm:ss') }</p>
          </div>
        );
      }
    },
  ];

  const [currentFilter, setCurrentFilter] = useState('none');
  const selectFilter = (key) => {
    setCurrentFilter(key);
  }

  const [keywords, setKeywords] = useState();
  const search = (e) => {
    setKeywords(e.target.value);
  }

  const [dataSource, setDataSource] = useState([]);
  const currentCollaborator = new URLSearchParams(props.routing.location.search).get('collaborator');

  useEffect(() => {
    rpgRequest('/api/admin/shipments')
      .then((res) => {
        setDataSource(res.data.map(r => ({...r, status: calcShipmentStatus(r)})));
      });
  }, []);

  var filterShipments = currentFilter == 'none' ? dataSource : dataSource.filter(s => s.status == currentFilter);
  if (!!keywords) {
    filterShipments = filterShipments.filter(s => s.title.toLowerCase().includes(keywords.toLowerCase()));
  }
  if (!!currentCollaborator) {
    filterShipments = filterShipments.filter(s => s.collaborators.find(c => c.user?.email == currentCollaborator));
  }

  return (
    <div className='all-shipments admin-shipments'>
      <div className='all-shipments__list'>
        <div className='all-shipments__list--searchbar'>
          <div className='all-shipments__list--searchbar-left'>
            <Input
              size='large'
              placeholder='search shipment'
              prefix={<i className='fa-solid fa-search' />}
              onChange={ debounce(search, 500) }
            />

            {
              Object.keys(ALL_STATUS).map((k) => (
                <label className={currentFilter == k ? 'current' : ''} key={k} onClick={ () => selectFilter(k) }>{ ALL_STATUS[k] }</label>
              ))
            }

            {
              !!currentCollaborator ? (
                <Tag color="#108ee9" closable onClose={ () => props.routing.replace(props.routing.location.pathname) }>
                  <b>{ currentCollaborator }</b>
                </Tag>
              ) : null
            }
          </div>

          <div className='all-shipments__list--searchbar-right'>
            <b>{ dataSource.length }</b> Shipments
          </div>
        </div>

        <Table
          rowKey={row => row.id}
          className='all-shipments__table'
          columns={COLUMNS}
          dataSource={filterShipments}
        />
      </div>
    </div>
  );
}

const calcShipmentStatus = (shipment) => {
  if (!!shipment.completed) {
    return "Completed";
  }

  if (!shipment.containers?.length) {
    return "EMPTY";
  }

  const allStatus = uniq((shipment.containers || []).map(c => c.status));

  if (uniq(allStatus.concat(["GateOut", "EmptyReturned"])).length == 2) {
    return "Completed";
  }

  return "Active";
}

export default inject('routing')(observer(AdminShipments));
