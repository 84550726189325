import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select } from 'antd';
import orderBy from 'lodash.orderby';

import CollaboratorBlock from '../CollaboratorBlock';
import CollaboratorInvitationLink from '../CollaboratorInvitationLink';
import Avatar from '../Avatar';

import './index.less';

const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const InviteCollaborators = (props) => {
  const { visible, shipment, collaboratorRelations, isAdmin } = props;
  const { addCollaborators, updateCollaboratorRole, removeCollaborator, onCancel } = props;

  const [selectedCollaboratorIds, setSelectedCollaboratorIds] = useState([]);

  useEffect(() => {
    setCollaboratorOptions(collaboratorRelations.filter(c => !(shipment?.collaborators || []).find(sc => sc.userId == c.id)));
  }, [shipment, collaboratorRelations, selectedCollaboratorIds]);

  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [role, setRole] = useState("FreightForwarder");

  const searchOptions = (searchInput) => {
    const options = collaboratorRelations.filter(c => !(shipment?.collaborators || []).find(sc => sc.userId == c.id)).filter((user) => {
      return (user.first_name + user.last_name).toLowerCase().includes(searchInput.toLowerCase()) || user.email.includes(searchInput.toLowerCase());
    });

    if (options.length == 0 && EMAIL_REGEXP.test(searchInput)) {
      options.push({
        id: `EMAIL-${searchInput}`,
        first_name: searchInput,
        email: searchInput,
      });
    }

    setCollaboratorOptions(options);
  }

  const invite = () => {
    if (selectedCollaboratorIds.length == 0) {
      return;
    }

    addCollaborators(shipment.id, selectedCollaboratorIds, role)
      .then(() => {
        setSelectedCollaboratorIds([]);
      });
  }

  if (!shipment) {
    return;
  }

  return (
    <Modal
      title={
        <div className='rpg-text-limit--one-line'>Share {`"${shipment.title}"`}</div>
      }
      open={visible}
      className='invite-members'
      onCancel={onCancel}
      footer={
        <CollaboratorInvitationLink
          shipment={shipment}
          role={role}
          invite={invite}
          disableConfirm={selectedCollaboratorIds.length == 0}
        />
      }
    >
      <div className='invite-members__search'>
        <div className='invite-members__search--select-members'>
          <Select
            mode="multiple"
            size="large"
            placeholder="Add Collaborator"
            value={selectedCollaboratorIds}
            onChange={setSelectedCollaboratorIds}
            onSearch={searchOptions}
            defaultActiveFirstOption={false}
            autoClearSearchValue={true}
            style={{
              width: '100%',
            }}
            options={collaboratorOptions.map((user) => ({
              label: (
                <CollaboratorBlock
                  relationship={{ user }}
                  onlyShowInfo
                />
              ),
              value: user.id,
            }))}
            notFoundContent="Please input a valid email address."
            filterOption={false}
            tagRender={({ label }) => {
              const { relationship } = label.props;
              return (
                <div style={{ display: 'flex', padding: '0 8px', height: '30px', border: '1px solid #D8D4D4', borderRadius: '50px', alignItems: 'center', marginRight: '8px', gap: '4px' }}>
                  <Avatar firstName={ relationship.user.first_name } lastName={ relationship.user.last_name } email={ relationship.user.email } size='small' />
                  { relationship.user.first_name }
                </div>
              );
            }}
          />

          <Select
            value={role}
            size="large"
            style={{ width: '158px', verticalAlign: 'middle', flexShrink: 0 }}
            onChange={setRole}
            options={[
              {
                value: 'FreightForwarder',
                label: 'Freight Forwarder',
              },
              {
                value: 'CustomsBroker',
                label: 'Customs Broker',
              },
              {
                value: 'TruckCompany',
                label: 'Truck Company',
              },
            ]}
          />
        </div>
      </div>

      <div className='invite-members__existing'>
        <div className='invite-members__existing--label'>People with access</div>
        {
          orderBy(
            shipment?.collaborators || [],
            [
              "role",
              "user.first_name"
            ],
          ).map((c) => (
            <div className='invite-members__existing--member-block' key={c.userId}>
              <CollaboratorBlock
                relationship={c}
                updateCollaboratorRole={updateCollaboratorRole}
                removeCollaborator={removeCollaborator}
                isAdmin={isAdmin}
              />
            </div>
          ))
        }
      </div>
    </Modal>
  );
};

InviteCollaborators.propTypes = {
  shipment: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  updateCollaboratorRole: PropTypes.func.isRequired,
  removeCollaborator: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  collaboratorRelations: PropTypes.array.isRequired,
  addCollaborators: PropTypes.func.isRequired,
};

export default InviteCollaborators;
