import React from 'react';

import './index.less';

const SignInLayout = (props) => {
  return (
    <div className='signin-layout'>
      <div className='signin-layout__logo'>
        <img src='/static/images/logo-with-color.svg' />
      </div>

      <div className='signin-layout__main'>
        { props.children }
      </div>
    </div>
  );
}

export default SignInLayout;
