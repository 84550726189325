import React from 'react';
import { Carousel } from 'antd';

import './index.less';

const SignUpLayout = (props) => {
  return (
    <div className='signup-layout'>
      <div className='signup-layout__main'>
        <div className='signup-layout__main--logo'>
          <img src='/static/images/logo-with-color.svg' />
        </div>

        { props.children }
      </div>

      <div className='signup-layout__bg'>
        <div className='signup-layout__bg--cover' style={{ background: 'url(/static/images/sidebar-bg.svg)' }} />
        <div className='signup-layout__bg--layout' />

        <div className='signup-layout__bg--main'>
          <div className='signup-layout__bg--text-content'>
            <div className='signup-layout__bg--first-title'>Real Time Collaboration Platform</div>
            <div className='signup-layout__bg--second-title'>For Cargo Information Empowerment</div>
            <div className='signup-layout__bg--div-block' />
            <div className='signup-layout__bg--description'>Cargo in time revolutionizes the way things move by creating a standard way to share, communicate, and collaborate in real time.</div>
          </div>

          <div className='signup-layout__bg--carousel'>
            <div className='signup-layout__bg--quote' style={{ background: 'url(/static/images/quote.svg)' }} />
            <Carousel
              className='cit-carousel'
              autoplay
              dotPosition='bottom'
              dots={{
                className: 'cit-carousel__dots'
              }}
            >
              <div className='cit-carousel__content'>
                <div className='cit-carousel__content--main'>
                With Cargo In Time, we’ve had great benefits, especially our customer feedback. When we share the status of their shipments in real-time, our customers feel blessed that they’ve got a supplier they can rely on. Internally, our efficiencies between teams have improved.
                </div>

                <div className='cit-carousel__content--userinfo'>
                 

                  <div>
                    <div className='cit-carousel__content--user-name'>-- Jonas Kim</div>
                    <div className='cit-carousel__content--user-role'>Freight Forwoder</div>
                  </div>
                </div>
              </div>

              <div className='cit-carousel__content'>
                <div className='cit-carousel__content--main'>
                We’ve grown by 30% with two-thirds less people, reducing operating costs by 60%. With Cargo In time, we can onboard customers faster, giving them the ability to track every shipment from anywhere. 
                </div>

                <div className='cit-carousel__content--userinfo'>
                 

                  <div>
                    <div className='cit-carousel__content--user-name'>-- Juarez</div>
                    <div className='cit-carousel__content--user-role'>Truck Company</div>
                  </div>
                </div>
              </div>

              <div className='cit-carousel__content'>
                <div className='cit-carousel__content--main'>
                The ability to keep things consolidated in one area but easily track the information, whether it was tracking shipments, expenditures or cost savings with just a few clicks -- that is the power we saw with CIT's solution.
                </div>

                <div className='cit-carousel__content--userinfo'>
                

                  <div>
                    <div className='cit-carousel__content--user-name'>-- Jack Washton</div>
                    <div className='cit-carousel__content--user-role'>Freight Forwoder</div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpLayout;
