import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';
import App from './containers/App';

import AuthStore from './stores/AuthStore';
import ShipmentStore from './stores/ShipmentStore.js';
import AppointmentStore from './stores/AppointmentStore.js';
import ChannelStore from './stores/ChannelStore.js';
import FileStore from './stores/FileStore.js';
import CollaboratorStore from './stores/CollaboratorStore.js';

import webSocketClient from './lib/webSocketClient.js';

import './index.less';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const authStore = new AuthStore();
const channelStore = new ChannelStore(webSocketClient, authStore);
const shipmentStore = new ShipmentStore(webSocketClient, channelStore);
const appointmentStore = new AppointmentStore(webSocketClient, shipmentStore);
const fileStore = new FileStore(webSocketClient);
const collaboratorStore = new CollaboratorStore();

const stores = {
  routing: routingStore,
  authStore,
  shipmentStore,
  appointmentStore,
  channelStore,
  fileStore,
  collaboratorStore
};

const history = syncHistoryWithStore(browserHistory, routingStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider {...stores}>
  {/*<React.StrictMode>*/}
      <Router history={history}>
        <App />
      </Router>
  {/*</React.StrictMode>*/}
  </Provider>
);
