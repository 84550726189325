import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Timeline, Table, Dropdown, Menu, Tooltip, message, Drawer, Select } from 'antd';
import { TerminalLinks } from '../../utils/constants';
import { formatDatetime } from '../../utils/index.js';
import get from 'lodash.get';

import rpgRequest from '../../lib/rpgRequest.js';

import AppointmentInfoModal from '../AppointmentInfoModal';

import './index.less';

const ContainersTable = (props) => {
  const { containers, carriers, isLoading, isAuthor, updateContainer, removeContainers, createPickUpAppointment } = props;
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [currentContainerId, setCurrentContainerId] = useState(null);

  const [detailedContainer, setDetailedContainer] = useState(null);

  const addToWatchList = (containerIds) => {
    rpgRequest(`/api/container_monitors`, 'post', { containerIds })
      .then((res) => {
        message.success('The container has added to watch list.', 5);
      })
      .catch((err) => {
        message.error('Server error', 5);
      });
  }

  const columns = [
    {
      title: 'Container #',
      dataIndex: 'unit_nbr',
      key: 'unit_nbr',
      width: 200,
      fixed: 'left',
      render: (value, record) => (
        <div className='containers-table__unitinfo' onClick={() => setDetailedContainer(record)}>
          <p className='bol'> B/L: { record.bol != 'none' ? record.bol : '--' }</p>
          <p className='cntr'><b>{ record.containerNum != 'N/A' ? record.containerNum : '' }</b></p>
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (value, record) => {
        return (
          <div className='containers-table__status'>
            <p className='label'>
              <span className={record.status}>{ record.status == 'Loading' ? 'Processing' : record.status.replace(/([A-Z])/g, ' $1').trim() }</span>
            </p>
            {
              record.status == 'GateOut' || record.status == 'EmptyReturned' ? (
                null
              ) : (
                record.status == 'Other' ? (
                  <p className='info'>
                    <label>{ record.substatus }</label>
                  </p>
                ) : (
                  <p className='info'>
                    {
                      record.available_for_pickup == 'No' ? (
                        <label className='unavailable'>Not available for pickup</label>
                      ) : null
                    }

                    {
                      record.available_for_pickup == 'Yes' ? (
                        <label className='available'>Available for pickup</label>
                      ) : null
                    }
                  </p>
                )
              )
            }
          </div>
        );
      }
    },
    {
      title: 'Carrier',
      dataIndex: 'scac',
      key: 'scac',
      align: 'center',
      width: 150,
      render: (value, record) => {
        if (!!record.scac) {
          return record.scac;
        }

        return record.status == 'Loading' && new Date() - new Date(record.updatedAt) < 30 * 60000 ? (
          null
        ) : (
          <Select
            size='small'
            placeholder='Carrier'
            style={{width: 120, display: 'block'}}
            dropdownMatchSelectWidth={false}
            showSearch
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            onChange={(v) => updateContainer(record.key, v)}
            options={
              carriers.map((c, i) => ({
                key: i,
                value: c.scac_code,
                label: c.scac_code
              }))
            }
          />
        )
      }
    },
    {
      title: 'Size/Type',
      key: 'size',
      align: 'center',
      width: 120,
      render: (value, record) => {
        if (record.basicInfo?.containerSize && record.basicInfo?.containerType) {
          return `${record.basicInfo?.containerSize }${ record.basicInfo?.containerType }`;
        } else {
          return "-";
        }
      }
    },
    {
      title: 'Holds Info',
      dataIndex: 'hold_flg',
      width: 400,
      key: 'hold_flg',
      render: (value, record) => {
        return (
          <div className='containers-table__holds'>
            {
              (record.holds_info || []).map((h) => {
                return (
                  <div className='containers-table__holds--block'>
                    <p>{h.type}</p>
                    <p className='status'>
                      {h.status || '-'}
                      {
                        h.detail && (h.status == 'Hold' || h.status == 'HOLD') ? (
                          <Tooltip
                            title={
                              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                  <div style={{color: '#818181', fontSize: '14px'}}>HOLD</div>
                                  <div style={{color: '#3C3C3C', fontSize: '14px'}}>{ h.detail.reason }</div>
                                </div>

                                <div>
                                  <div style={{color: '#818181', fontSize: '14px'}}>HOLD TIME</div>
                                  <div style={{color: '#3C3C3C', fontSize: '14px'}}>{ h.detail.time ? moment(h.detail.time).format('MM/DD/YYYY HH:mm') : '-' }</div>
                                </div>
                              </div>
                            }
                            color='#FFF'
                            placement='bottom'
                            overlayInnerStyle={{padding: '18px', width: '280px'}}
                          >
                            <i className='fa-solid fa-circle-exclamation' style={{ marginLeft: '4px', color: 'red', cursor: 'pointer' }} />
                          </Tooltip>
                        ) : null
                      }
                    </p>
                  </div>
                );
              })
            }
          </div>
        );
      }
    },
    {
      title: 'Last Free Day',
      dataIndex: 'lastfree_dt',
      key: 'lastfree_dt',
      width: 100,
      render: (content, record) => {
        return (
          <div className='containers-table__last-free-day'>
            { get(record, 'last_free_day') ? moment(get(record, 'last_free_day')).format('MM/DD/YY') : "-" }
          </div>
        );
      }
    },
    {
      title: 'Pickup To Schedule',
      dataIndex: 'pickup',
      key: 'pickup',
      width: 240,
      render: (content, record) => {
        const terminalCode = record.terminalCode;
        const terminalInfo = TerminalLinks[terminalCode];
        const terminalName = terminalInfo?.abbrev || terminalInfo?.name || terminalCode || "-";
        const gateOutEvent = (record?.eventsInfo?.events || []).find(e => e.desc == 'GateOut' || e.citStatus == 'GateOut');
        const pickedUp = gateOutEvent?.eventTime ? moment(gateOutEvent.eventTime).format('MM/DD/YY HH:mm') : null;

        return (
          <div className='containers-table__pickup-schedule'>
            {
              !['EmptyReturned', 'GateOut'].includes(record.status) ? (
                record.scac == 'MATS' ? (
                  <a style={{textDecoration: 'underline'}} href="https://www.emodal.com/" target='_blank'>Matson</a>
                ) : (
                  terminalInfo?.link ? (
                    <a style={{textDecoration: 'underline'}} href={terminalInfo?.link} target='_blank'>{ terminalName }</a>
                  ) : terminalName
                )
              ) : (
                <span>{ record.scac == 'MATS' ? 'Matson' : terminalName }</span>
              )
            }

            <br />

            {
              ['EmptyReturned', 'GateOut'].includes(record.status) ? (
                pickedUp ? <span style={{ fontSize: '12px' }}>{ `Picked Up: ${pickedUp}` }</span> : null
              ) : (
                record.appt_time ? <span style={{ fontSize: '12px' }}>{ `Appt: ${record.appt_time}` }</span> : null
              )
            }
          </div>
        );
      }
    },
    // {
    //   title: 'Empty To Return',
    //   width: 120,
    //   align: 'center',
    //   render: (record) => {
    //     const emptyReturnedEvent = (record?.eventsInfo?.events || []).find(e => e.desc == 'EmptyReturned');
    //     if (!emptyReturnedEvent) {
    //       return '-';
    //     }

    //     return moment(emptyReturnedEvent.eventTime).format('YYYY-MM-DD');
    //   }
    // },
    {
      title: 'Detention Period',
      width: 100,
      align: 'center',
      render: (record) => {
        const gateOutEvent = (record?.eventsInfo?.events || []).find(e => e.desc == 'GateOut' || e.citStatus == 'GateOut');
        if (record.status == 'EmptyReturned') {
          return '-';
        }

        if (!gateOutEvent) {
          return '';
        }

        const days = (moment() - moment(gateOutEvent.eventTime)) / 86400000;
        if (days < 1) {
          return '< 1 day';
        }

        if (days >= 1 && days < 5) {
          return `${Math.floor(days)} days`;
        }

        return <span style={{color: '#FF0000'}}>{Math.floor(days)} days</span>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      fixed: 'right',
      render: (record) => {
        if (!isAuthor) {
          return <div className='containers-table__actions'>-</div>;
        }

        return (
          <div className='containers-table__actions'>
            <Dropdown
              trigger={["click"]}
              placement='top'
              overlay={menu(record)}
            >
              <i className='fa-solid fa-ellipsis-vertical' style={{padding: '0 12px'}} />
            </Dropdown>
          </div>
        );
      }
    }
  ];

  const menu = (record) => (
    <Menu
      items={[
        // {
        //   key: 'add-appointment',
        //   label: 'Add appointment info',
        //   disabled: !!record.appointment,
        //   onClick: () => setCurrentContainerId(record.key)
        // },
        // {
        //   key: 'add-to-watch-list',
        //   label: 'Add to watch list',
        //   onClick: () => addToWatchList([record.key])
        // },
        {
          key: 'delete',
          label: 'Delete',
          onClick: () => removeContainers([record.key])
        }
      ]}
    />
  );

  return (
    <div className='containers-table-section'>
      <Table
        className='containers-table'
        loading={isLoading}
        columns={columns}
        dataSource={containers.map((r) => ({key: r.id, containerNum: r.containerNum, status: r.status, substatus: r.substatus, appointment: r.appointment, basicInfo: r.basic_info, eventsInfo: r.events_info, holds_info: r.holds_info, last_free_day: r.last_free_day, available_for_pickup: r.available_for_pickup, bol: r.bol, terminalCode: r.terminal_code, appt_time: r.appt_time, scac: r.scac, updatedAt: r.updated_at}))}
        rowSelection={{
          onChange: (keys) => setSelectedContainers(keys)
        }}
        pagination={{
          pageSize: 20
        }}
        scroll={{
          x: 1300,
        }}
      />

      {
        selectedContainers.length > 0 ? (
          <div className='containers-table-section__selector'>
            <div className='containers-table-section__selector--item highlight'>
              Selected <b>{selectedContainers.length}</b>
            </div>

            <div className='containers-table-section__selector--item' onClick={() => removeContainers(selectedContainers).then(() => setSelectedContainers([]))}>
              <i className='fa-solid fa-file-circle-xmark' />
              Remove
            </div>
          </div>

        ) : null
      }

      <AppointmentInfoModal
        visible={!!currentContainerId}
        cancel={() => setCurrentContainerId(null)}
        containerId={currentContainerId}
        createPickUpAppointment={createPickUpAppointment}
      />

      <Drawer
        open={!!detailedContainer}
        onClose={() => setDetailedContainer(null)}
        title={`${get(detailedContainer, 'containerNum')} [${get(detailedContainer, 'basicInfo.containerSize', '')}${get(detailedContainer, 'basicInfo.containerType', '')}]`}
        width={520}
        className='container-detail-drawer'
        maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0)'}}
      >
        <div className='container-detail'>
          <div className='container-detail__vessel-info'>
            <div className='container-detail__vessel-info--block'>
              <p><label>Terminal:</label> {get(detailedContainer, 'basicInfo.terminalInfo.terminalName') || get(detailedContainer, 'terminalCode')}</p>
            </div>

            <div className='container-detail__vessel-info--block'>
              <p><label>Vessel:</label> {get(detailedContainer, 'basicInfo.firstVessel.vessel')} {get(detailedContainer, 'basicInfo.firstVessel.voyage')}</p>
            </div>

            <div className='container-detail__vessel-info--block'>
              <p>
                {
                  get(detailedContainer, 'basicInfo.firstVessel.ata', null) ? (
                    <><label>ATA:</label> {get(detailedContainer, 'eventsInfo.ata')}</>
                  ) : (
                    <><label>ETA:</label> {get(detailedContainer, 'eventsInfo.eta')}</>
                  )
                }
              </p>
            </div>
          </div>

          <div className='container-detail__vessel-info'>
            <div className='container-detail__vessel-info--block'>
              <label>Appt Time:</label> {get(detailedContainer, 'appt_time')}
            </div>
          </div>

          <div className='container-detail__timeline'>
            <Timeline className='container-timeline' mode='left'>
              {
                (detailedContainer?.eventsInfo?.events || []).map((e,i) => (
                  <Timeline.Item
                    className={`container-timeline__item ${i == 0 ? 'activated' : ''}`}
                    color={ i == 0 ? 'green' : 'gray' }
                    label={
                      <div className='container-timeline__date'>
                        <div className='container-timeline__date--info'>{ moment(e.eventTime).format('MM/DD') }</div>
                        <div className='container-timeline__date--info'>{ moment(e.eventTime).format('HH:mm') }</div>
                      </div>
                    }
                  >
                    <div className='container-timeline__content'>
                      <div className='container-timeline__content--title'>{e.desc}</div>
                      <div className='container-timeline__content--info'>{e.location}</div>
                    </div>
                  </Timeline.Item>
                ))
              }
            </Timeline>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

ContainersTable.propTypes = {
  containers: PropTypes.array.isRequired,
  carriers: PropTypes.array.isRequired,
  updateContainer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  removeContainers: PropTypes.func.isRequired,
  createPickUpAppointment: PropTypes.func.isRequired,
};

export default ContainersTable;
