import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { Modal } from 'antd';

import './index.less';

const JoinCompanyModal = (props) => {
  const { visible } = props;
  const { onCancel, findCompany, joinCompany } = props;

  const [companyName, setCompanyName] = useState(null);

  const codeInput = useRef(null);

  const searchCompany = async (e) => {
    setCompanyName(null);
    findCompany(e.target.value)
      .then((company) => {
        setCompanyName(company && company.name);
      });
  }

  const onConfirm = () => {
    const companyCode = codeInput.current.value;
    joinCompany(companyCode)
      .then(() => {
        onCancel();
      });
  }

  return (
    <Modal
      open={ visible }
      title='Join Company'
      okText='Join'
      onCancel={ onCancel }
      onOk={ onConfirm }
      okButtonProps={{disabled: !companyName}}
    >
      <div className='join-company-modal'>
        <div className='join-company-modal__block'>
          <div className='join-company-modal__block--label'>Company Code*</div>
          <input ref={codeInput} onChange={ debounce(searchCompany, 500) } />
        </div>

        <div className='join-company-modal__block'>
          <div className='join-company-modal__block--label'>Company Name*</div>
          <div className='join-company-modal__block--content'>
            { companyName }
          </div>
        </div>
      </div>
    </Modal>
  );
};

JoinCompanyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  findCompany: PropTypes.func.isRequired,
  joinCompany: PropTypes.func.isRequired,
};

export default JoinCompanyModal;
