import { makeAutoObservable, observable, computed } from 'mobx';
import update from 'react-addons-update';
import { message } from 'antd';
import sum from 'lodash.sum';
import orderBy from 'lodash.orderby';

import rpgRequest from '../lib/rpgRequest.js';

const SOCKET_TYPE = 'CHANNEL';

class ChannelStore {
  @observable channels = {};
  @observable currentChannelId = null;

  constructor(socketClient) {
    this.socket = socketClient.socket;

    makeAutoObservable(this);
  }

  init = () => {
    this._initEventListener();
    this.fetchAllChannels();
  }

  _initEventListener = () => {
    this.socket.on(SOCKET_TYPE, (action, data) => {
      switch (action) {
        case 'NEW_MESSAGE': {
          if (this.channels[data.chatChannelId].messages.slice(-1).id == data.id) {
            return;
          }

          this.channels = update(this.channels, {
            [data.chatChannelId]: {
              messages: {
                $unshift: [data]
              }
            }
          });
          break;
        }
        case 'UPDATE_CHANNEL': {
          this.channels = update(this.channels, {
            [data.id]: {
              $set: data
            }
          });
          break;
        }
        case 'REFRESH_CHANNELS': {
          this.fetchAllChannels();
          break;
        }
      }
    });
  }

  createChannel = (shipmentId, callback = () => {}, errCallback = () => {}) => {
    rpgRequest(`/api/shipments/${shipmentId}/chat_channels`, 'post')
      .then((res) => {
        message.success('You created the channl successfully.', 5);
        this.channels[res.data.id] = res.data;
        this.currentChannelId = res.data.id;
        callback(res.data);
      })
      .catch((err) => {
        message.error('Failed to create the channel.', 5);
        errCallback(err);
      });
  }

  fetchAllChannels = () => {
    this.socket.emit(SOCKET_TYPE, 'FETCH_ALL_CHANNELS', null, (data) => {
      let channels = {};
      data.forEach((c) => {
        channels[c.id] = c;
      });
      this.channels = channels;
      this.currentChannelId = this.sortedChannels[0]?.id;
    });
  }

  fetchMoreMessages = () => {
    const channelMessages = this.channels[this.currentChannelId].messages;
    if (!channelMessages.length) {
      this.channels = update(this.channels, {
        [this.currentChannelId]: {
          noMoreMessages: {
            $set: true
          }
        }
      });
      return;
    }

    this.socket.emit(SOCKET_TYPE, 'FETCH_MORE_MESSAGES', { channelId: this.currentChannelId, messageCursorId: channelMessages[channelMessages.length - 1].id } , (data) => {
      if (!data?.length) {
        this.channels = update(this.channels, {
          [this.currentChannelId]: {
            noMoreMessages: {
              $set: true
            }
          }
        });
        return;
      }

      this.channels = update(this.channels, {
        [this.currentChannelId]: {
          messages: {
            $push: data
          }
        }
      });
    });
  }

  selectChannel= (channelId, currentUserId) => {
    this.currentChannelId = channelId;
    this.readMessages(currentUserId, channelId);
  }

  readMessages = (currentUserId, channelId) => {
    if (!!this.calculateMessagesCount(currentUserId)(channelId)()) {
      this.socket.emit(SOCKET_TYPE, 'READ_MESSAGES', { channelId });
    }
  }

  sendMessage = (channelId) => (data) => {
    this.socket.emit(SOCKET_TYPE, 'NEW_MESSAGE', { channelId, ...data });
  }

  calculateMessagesCount = (userId) => (channelId) => (type = 'Unread') => {
    let calcChannels = Object.values(this.channels);
    if (!!channelId) {
      calcChannels = Object.values(this.channels).filter(c => c.id == channelId);
    }

    return sum(calcChannels.map((c) => {
      return c._count.messagesRelatedUsers || c.messages.filter(m => m.relatedUsers[type][userId]).length;
    }));
  }

  @computed
  get sortedChannels() {
    return orderBy(Object.values(this.channels), (c) => {
      if (c.messages?.length > 0) {
        return new Date(c.messages[0].created_at).valueOf();
      }

      return new Date(c.created_at).valueOf();
    }, ['desc']);
  }
}

export default ChannelStore;
