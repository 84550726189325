export const UserRoleOnShipment = {
  Admin: {
    value: "Admin",
    label: "Admin"
  },
  FreightForwarder: {
    value: "FreightForwarder",
    label: "Freight Forwarder"
  },
  CustomsBroker: {
    value: 'CustomsBroker',
    label: 'Customs Broker',
  },
  TruckCompany: {
    value: 'TruckCompany',
    label: 'Truck Company',
  }
};

export const FileCategories = {
  MasterBill: {
    value: "MasterBill",
    label: "Master Bill",
  },
  HouseBill: {
    value: "HouseBill",
    label: "House Bill",
  },
  PackingList: {
    value: "PackingList",
    label: "PackingList"
  },
  Others: {
    value: "Others",
    label: "Others"
  }
};

export const TerminalLinks = {
  "FMS": {
    name: "FMS - Community Portal",
    abbrev: "FMS",
    link: "https://portal.fenixmarineservices.com/apptmt-app/home"
  },
  "PCT": {
    name: "SSA PCT",
    abbrev: "PCT",
    link: "https://pct.tideworks.com/fc-PCT/default.do"
  },
  "PA": {
    name: "SSA Pier A",
    abbrev: "PIER A",
    link: "https://piera.tideworks.com/fc-PA/default.do"
  },
  "YTI": {
    name: "YTI Portal (Lynx)",
    abbrev: "YTI",
    link: "https://lynx.yti.com/Default.aspx",
  },
  "ITS": {
    name: "ITS Portal (ITS TMS)",
    abbrev: "ITS",
    link: "https://tms.itslb.com/tms2/Account/Login",
  },
  "ETSLAX": {
    name: "Everport Portal (Etslink)",
    abbrev: "ETS",
    link: "https://www.etslink.com/"
  },
  "ETSTIW": {
    name: "Everport Portal (Etslink)",
    abbrev: "ETS",
    link: "https://www.etslink.com/"
  },
  "ETSOAK": {
    name: "Everport Portal (Etslink)",
    abbrev: "ETS",
    link: "https://www.etslink.com/"
  },
  "ETS": {
    name: "Everport Portal (Etslink)",
    abbrev: "ETS",
    link: "https://www.etslink.com/"
  },
  "APMT-LA": {
    name: "APM Termpoint (Appt System)",
    abbrev: "APMT",
    link: "https://termpoint.apmterminals.com/",
  },
  "APMT": {
    name: "APM Termpoint (Appt System)",
    abbrev: "APMT",
    link: "https://termpoint.apmterminals.com/",
  },
  "LBCTE": {
    name: "LBCT Portal (+ Appt System)",
    abbrev: "LBCT",
    link: "https://www.lbct.com/LoginWithUrl/ViewMyList"
  },
  "LBCT": {
    name: "LBCT Portal (+ Appt System)",
    abbrev: "LBCT",
    link: "https://www.lbct.com/LoginWithUrl/ViewMyList"
  },
  "EMODAL": {
    name: "eModal (Appt System)",
    abbrev: "EMODAL",
    link: "https://www.emodal.com/"
  },
  "TTI": {
    name: "TTI Portal (+ Appt System)",
    abbrev: "TTI",
    link: "https://www.ttilgb.com/main/index.do"
  },
  "WBCT": {
    name: "WBCT Portal (+ Appt System)",
    abbrev: "WBCT",
    link: "https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA"
  },
  "voyage": {
    name: "Voyage Control (Appt System)",
    link: "https://yti.voyagecontrol.com/"
  },
  "M60": {
    name: "SSAT- Pier C (Matson)",
    abbrev: "PIER C",
    link: ""
  },
  "B63": {
    name: "SSAT- B63 (Matson)",
    link: ""
  },
  "MAHR": {
    name: "MAHR Terminal",
    link: ""
  },
  "VITAP": {
    name: "Virginia International Gateway (VIG)",
    link: ""
  },
  "VITNI": {
    name: "Norfolk International (NIT)",
    link: ""
  },
  "CCT": {
    name: "COLON CONTAINER TERMINAL",
    link: ""
  },
  "TRP1": {
    name: "TraPac LLC-Log Angeles",
    link: ""
  },
  "STL": {
    name: "Shippers Transport Express - Carson,Carson",
    link: ""
  },
  "WT": {
    name: "Wando Welch Terminal",
    link: ""
  },
  "PNCT": {
    name: "PNCT Terminal",
    link: ""
  },
  "WUT": {
    name: "Washington United Terminals",
    link: ""
  },
  "HUSKY": {
    name: "Husky Terminal and Stevedoring, Inc.",
    link: ""
  },
  "TRAPAC": {
    name: "TraPac container terminals",
    abbrev: "TraPac",
    link: "https://www.emodal.com/"
  },
  "TraPac": {
    name: "TraPac container terminals",
    abbrev: "TraPac",
    link: "https://www.emodal.com/"
  }
}

export const TEMP_ADMIN_EMAILS = ['zhuoyuyang@gmail.com', 'xander@rpgss.com', 'wei.y@rpgss.com'];
