import React from 'react';
import PropTypes from 'prop-types';
import stc from 'string-to-color';

import md5 from 'md5';

import './index.less';

const Avatar = (props) => {
  const { size, firstName, lastName, email } = props;

  return (
    <div className={`rpg-avatar ${size}`}>
      <div className='rpg-avatar__default' style={{backgroundColor: stc(firstName + ' ' + lastName)}}>
        {firstName?.slice(0,1).toUpperCase()}{lastName?.slice(0,1).toUpperCase()}
      </div>

      <div className='rpg-avatar__image' style={{backgroundImage: `url(https://www.gravatar.com/avatar/${md5(email)}?d=blank)`}} />
    </div>
  );
}

Avatar.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.string,
};

export default Avatar;
