import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import { Table, Input, Tag, message } from 'antd';
import moment from 'moment';
import { useLocation } from "react-router-dom";

import rpgRequest from '../../lib/rpgRequest.js';

import './index.less';

const AdminContainers = (props) => {
  //# Force trigger refresh after query params changed.
  useLocation();

  const columns = [
    {
      title: 'Container #',
      key: 'container#',
      render: (record) => {
        return (
          <div className='admin-containers__container-num'>
            <p className='admin-containers__container-num--bol'>B/L: {record.bol == 'none' ? '--' : record.bol}</p>
            <p className='admin-containers__container-num--num'>{record.containerNum}</p>
          </div>
        );
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (content) => {
        return (
          <div className='admin-containers__status'>
            <span className={content}>{content}</span>
          </div>
        );
      }
    },
    {
      title: 'Shipment',
      key: 'shipment',
      render: (record) => {
        return (
          <div className='admin-containers__creator' onClick={filterShipment(record.shipment?.id)}>
            <p className='admin-containers__creator--name'>{ record.shipment?.title }</p>
            <p className='admin-containers__creator--email'>{ record.shipment?.id }</p>
          </div>
        );
      }
    },
    {
      title: 'Creator',
      key: 'creator',
      render: (record) => {
        return (
          <div className='admin-containers__creator' onClick={filterCreator(record.user?.email)}>
            <p className='admin-containers__creator--name'>{ `${record.user?.first_name} ${record.user?.last_name}` }</p>
            <p className='admin-containers__creator--email'>{ record.user?.email }</p>
          </div>
        );
      }
    },
    {
      title: 'Scac',
      key: 'scac',
      dataIndex: 'scac'
    },
    {
      title: 'Terminal',
      key: 'terminal',
      dataIndex: 'terminal_code'
    },
    {
      title: 'Last Updated By',
      key: 'last_updated_by',
      dataIndex: 'last_updated_by'
    },
    {
      title: 'Created At',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (content) => {
        return moment(content).format('MM/DD/YYYY HH:mm');
      }
    },
    {
      title: 'Updated At',
      key: 'updated_at',
      dataIndex: 'updated_at',
      render: (content) => {
        return moment(content).format('MM/DD/YYYY HH:mm');
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: () => {
      }
    }
  ];

  const filterShipment = (id) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('shipment', id);
    props.routing.push(`?${searchParams.toString()}`);
  }
  const filterCreator = (email) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('creator', email);
    props.routing.push(`?${searchParams.toString()}`);
  }
  const removeSearchParamsFilter = (key) => () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    props.routing.replace(`?${searchParams.toString()}`);
  }

  const [filter, setFilter] = useState('');
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    rpgRequest('/api/admin/containers')
      .then((res) => {
        setDataSource(res.data);
      });
  }, []);

  const creator = new URLSearchParams(props.routing.location.search).get('creator');
  const shipment = new URLSearchParams(props.routing.location.search).get('shipment');

  var filteredSource = dataSource.filter((r) => {
    return `${r.user?.first_name} ${r.user?.last_name}`.toLowerCase().includes(filter) || r.user?.email.toLowerCase().includes(filter) || r.shipment?.title.toLowerCase().includes(filter) || r.containerNum.toLowerCase().includes(filter) || r.bol.toLowerCase().includes(filter);
  });
  if (!!creator) {
    filteredSource = filteredSource.filter(s => s.user?.email == creator);
  }
  if (!!shipment) {
    filteredSource = filteredSource.filter(s => s.shipmentId == shipment);
  }

  return (
    <div className='admin-containers'>
      <Input
        size='large'
        style={{marginBottom: '16px', width: '650px', marginRight: '16px'}}
        placeholder='Search by container number, bol, shipment title, creator name, creator email'
        prefix={<i className='fa-solid fa-search' />}
        onChange={(e) => setFilter(e.target.value.toLowerCase())}
      />

      {
        !!creator ? (
          <Tag color="#108ee9" closable onClose={removeSearchParamsFilter('creator')}>
            Creator: <b>{ creator }</b>
          </Tag>
        ) : null
      }

      {
        !!shipment ? (
          <Tag color="#f8a100" closable onClose={removeSearchParamsFilter('shipment')}>
            Shipment Id: <b>{ shipment }</b>
          </Tag>
        ) : null
      }

      <Table
        rowKey={row => row.id}
        dataSource={filteredSource}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <div className='cron-job-logs__detail_panel'>
              { JSON.stringify(record, null, 8) }
            </div>
          )
        }}
      />
    </div>
  );
}

export default inject('routing')(observer(AdminContainers));
