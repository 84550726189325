import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Table, Input, Modal, Select, message } from 'antd';
import rpgRequest from '../../lib/rpgRequest.js';

import './index.less';

const TERMINALS = ['LBCT', 'ITS', 'ETS', 'YTI', 'FMS', 'APMT', 'WBCT', 'PCT', 'TTI', 'TRAPAC'];
const CARRIERS = ['MATS', 'EGLV', 'MAEU', 'MSCU', 'COSU', 'CULU', 'ONEY', 'SMLM', 'YMLU', 'HDMU', 'TraPac', 'TRAPAC', 'OOLU', 'CMDU']

const STATUS_OPTIOSN = [
  { label: 'ALL', value: '' },
  { label: 'OnVessel', value: 'OnVessel' },
  { label: 'Arrived', value: 'Arrived' },
  { label: 'Discharged', value: 'Discharged' },
  { label: 'GateOut', value: 'GateOut' },
  { label: 'EmptyReturned', value: 'EmptyReturned' },
];

export const AdminContainersCache = (props) => {
  const columns = [
    {
      title: 'Container Number',
      dataIndex: 'containerNum',
      key: 'containerNum',
    },
    {
      title: 'Bol',
      dataIndex: 'bol',
      key: 'bol',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'SCAC',
      dataIndex: 'scac',
      key: 'scac',
      render: (content, record) => {
        return (
          <Select
            style={{ width: 100 }}
            value={content}
            onChange={changeScacCode(record.bol, record.containerNum)}
            options={CARRIERS.map((c) => {
              return {
                value: c,
                label: c
              };
            })}
          />
        );
      }
    },
    {
      title: 'Terminal Code',
      dataIndex: 'terminal_code',
      key: 'terminal_code',
      render: (content, record) => {
        return (
          <Select
            disabled={record.scac == 'MATS'}
            style={{ width: 100 }}
            value={content}
            onChange={changeTerminalCode(record.bol, record.containerNum)}
            options={TERMINALS.map((t) => {
              return {
                value: t,
                label: t
              };
            })}
          />
        );
      }
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <div className='containers-cache__btns'>
            <button className='success' onClick={runningTask(record.bol, record.containerNum)}>Run</button>
            <button className='warning' onClick={deleteContainerCache(record.bol, record.containerNum)}>Delete</button>
          </div>
        );
      }
    }
  ];

  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    rpgRequest('/api/admin/shadow-containers')
      .then((res) => {
        setDataSource(res.data);
      });
  }, []);

  const changeScacCode = (bol, containerNum) => (scac) => {
    Modal.confirm({
      title: 'Are you sure you want to upate the scac code?',
      onOk: () => {
        rpgRequest(`/api/admin/shadow-containers/${encodeURIComponent(bol)}/${encodeURIComponent(containerNum)}`, 'PUT', {
          scacCode: scac,
        })
          .then((res) => {
            const newDataSource = [...dataSource].map((d) => {
              if (d.bol == bol && d.containerNum == containerNum) {
                d = {...d, scac};
              }

              return d;
            });

            message.success(`The container with number ${containerNum} and bol ${bol} is changed scac code to ${scac}, it will scrape data immediately.`);
            setDataSource(newDataSource);
          });
      }
    });
  }

  const changeTerminalCode = (bol, containerNum) => (terminal_code) => {
    Modal.confirm({
      title: 'Are you sure you want to upate the terminal code?',
      onOk: () => {
        rpgRequest(`/api/admin/shadow-containers/${encodeURIComponent(bol)}/${encodeURIComponent(containerNum)}`, 'PUT', {
          terminalCode: terminal_code,
        })
          .then((res) => {
            const newDataSource = [...dataSource].map((d) => {
              if (d.bol == bol && d.containerNum == containerNum) {
                d = {...d, terminal_code};
              }

              return d;
            });

            message.success(`The container with number ${containerNum} and bol ${bol} is changed terminal code to ${terminal_code}, it will scrape data immediately.`);
            setDataSource(newDataSource);
          });
      }
    });
  }

  const runningTask = (bol, containerNum) => () => {
    Modal.confirm({
      title: 'Are you sure you want to start running this task?',
      onOk: () => {
        rpgRequest(`/api/admin/shadow-containers/${encodeURIComponent(bol)}/${encodeURIComponent(containerNum)}/running-task`, 'POST', {})
          .then((res) => {
            message.success("The task has been queued.");
          });
      }
    });
  }

  const deleteContainerCache = (bol, containerNum) => () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this container cache?',
      onOk: () => {
        rpgRequest(`/api/admin/shadow-containers/${encodeURIComponent(bol)}/${encodeURIComponent(containerNum)}`, 'DELETE')
          .then((res) => {
            const filteredContainerCache = dataSource.filter((sc) => {
              return sc.bol != res.data.bol && sc.containerNum != res.data.containerNum;
            });
            setDataSource(filteredContainerCache);
          });
      }
    });
  }

  const [filter, setFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');

  return (
    <div className='containers-cache'>
      <Input
        size='large'
        style={{marginBottom: '16px', width: '300px'}}
        placeholder='Input container number or bol number.'
        onChange={(e) => setFilter(e.target.value)}
      />

      <Select
        size='large'
        value={statusFilter}
        style={{marginBottom: '16px', marginLeft: '16px', width: '120px'}}
        options={STATUS_OPTIOSN}
        onChange={setStatusFilter}
      />

      <Table
        dataSource={dataSource.filter(d => d.containerNum.includes(filter) || d.bol.includes(filter)).filter(d => statusFilter == 'ALL' ? true : d.status == statusFilter)}
        columns={columns}
      />
    </div>
  );
};

export default AdminContainersCache;
