import Papa from 'papaparse';
import XLSX from 'xlsx';

export async function readCSV(file) {
  let text = await file.text();
  const res = Papa.parse(text);

  return res.data;
}

export async function readXLSX(file, ...colsGroup) {
  let data = await readFileAsArrayBuffer(file);
  let workbook = XLSX.read(data, {type: 'array'});

  let res = [];
  for (let i = 0; i <= workbook.SheetNames.length - 1; i++) {
    let worksheet = workbook.Sheets[workbook.SheetNames[i]];
    let jsonRes = XLSX.utils.sheet_to_json(worksheet);
    let columns = colsGroup[i];

    if (!columns) {
      continue;
    }

    if (!columns || columns.length == 0) {
      columns = Object.keys(jsonRes[0]);
    }
    let rows = jsonRes.map((row) => (
      columns.map(col => row[col])
    ));

    res.push([columns, ...rows]);
  }

  return res;
}

export function readFileAsArrayBuffer(file) {
  return new Promise((resolve) => {
    file.arrayBuffer().then(res => resolve(res));
  });
}
