import React from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import { Form, Input, Button } from 'antd';

import './index.less';

const SignIn = (props) => {
  const [form] = Form.useForm();
  const { authStore } = props;

  const signIn = (data) => {
    authStore.signIn(data);
  }

  return (
    <div className='signin'>
      <div className='signin-layout__title'>Login to your account</div>

      <Form
        form={form}
        className='signin-layout__form'
        layout="vertical"
        size='large'
        onFinish={signIn}
      >
        <Form.Item label={<span style={{color: '#1F3858', fontSize: '16px'}}>Email</span>}>
          <Form.Item
            name='email'
            rules={[
              { required: true, message: 'Please input your email!' },
              { pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/, message: 'Email does not match the format.' }
            ]}
            noStyle
          >
            <Input placeholder='Email' />
          </Form.Item>
        </Form.Item>

        <Form.Item label={<span style={{color: '#1F3858', fontSize: '16px'}}>Password</span>}>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
            noStyle
          >
            <Input.Password placeholder='Password' />
          </Form.Item>
        </Form.Item>

        <Link className='signin-layout__link-align-right' to='/forget-password'>Forget password?</Link>

        <Form.Item>
          <Button htmlType='submit' className='signin-layout__submit-button'>
            Sign in
          </Button>
        </Form.Item>
      </Form>

      <div className='signin-layout__text-info'>
        Don’t have an account yet? <Link to='/signup'>Register now!</Link>
      </div>
    </div>
  );
};

export default inject("authStore")(observer(SignIn));
