import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';

import './index.less';

const TeamInfo = (props) => {
  const { isAdmin, members, inviteMember, removeMember, reloadCompanyMembers } = props;

  const [isShowModal, setIsShowModal] = useState(false);
  const [form] = Form.useForm();

  const sendInvitation = () => {
    form.validateFields()
      .then((values) => {
        inviteMember(values);
        setIsShowModal(false);
      })
      .catch((errs) => {
        console.error(errs);
      });
  }

  const removeUser = (user) => () => {
    Modal.confirm({
      title: 'Are you sure you want to remove the user?',
      onOk: () => {
        removeMember(user._id)
          .then(() => {
            reloadCompanyMembers();
          });
      }
    });
  }

  return (
    <div className='team-info'>
      <div className='team-info__team-block'>
        <div className='team-info__team-block--label'>
          Team Settings

          {
            isAdmin ? (
              <div className='team-info__team-block--label-btn' onClick={() => setIsShowModal(true)}>
                <PlusCircleOutlined style={{marginRight: '8px'}} />
                Add new members
              </div>
            ) : null
          }
        </div>

        <div className='team-info__team-block--content'>
          <table className='team-info__team-table'>
            <thead>
              <tr>
                <th>User</th>
                <th>Email</th>
                <th>Company role</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {
                members.map((m) => (
                  <tr key={m.email}>
                    <td>{ `${m.firstName} ${m.lastName}` }</td>
                    <td>{ m.email }</td>
                    <td>{ m.type == 'COMPANY_ADMIN' ? 'Admin' : 'Member' }</td>
                    <td>
                      {
                        isAdmin && m.type != 'COMPANY_ADMIN' ? (
                          <div className='action-in-table' onClick={removeUser(m)}>Remove</div>
                        ) : null
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        className='team-info__invitation-modal'
        title='Invite Team Members'
        okText='Confirm'
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        onOk={sendInvitation}
      >
        <Form
          form={form}
          layout='vertical'
          size='large'
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Please input your email!' },
              { pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/, message: 'Email does not match the format.' }
            ]}
          >
            <Input placeholder='Please input the email' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

TeamInfo.propTypes = {
  members: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  inviteMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  reloadCompanyMembers: PropTypes.func.isRequired,
};

export default TeamInfo;
