import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, Menu, Drawer, Modal, message } from 'antd';

import CreateShipmentModal from '../../components/CreateShipmentModal';
import ChannelChat from '../../components/ChannelChat';

import Avatar from '../../components/Avatar';
import InviteCollaborators from '../../components/InviteCollaborators';

import { UserRoleOnShipment } from '../../utils/constants.js';

import './index.less';

const ShipmentLayout = (props) => {
  const [showInviteCollaborators, setShowInviteCollaborators] = useState(false);

  const { id } = useParams();
  const { hideTopHeader } = props;

  const { collaboratorRelations } = props.collaboratorStore;
  const { channels, sendMessage, readMessages, fetchMoreMessages } = props.channelStore;
  const { socket, init, currentShipment, shipments, setCurrentShipment, completeShipment, deleteShipment, updateCollaboratorRole, removeCollaborator, addCollaborators, createChannelByShipment  } = props.shipmentStore;
  const { currentUser } = props.authStore;
  const admin = (currentShipment?.collaborators || []).find(c => c.role == 'Admin');

  useEffect(() => {
    if (!!socket) {
      init();
    }
  }, [socket]);

  useEffect(() => {
    setCurrentShipment(id)
      .catch(() => {
        message.error('The shipment can not be found.', 5);
        props.routing.replace(`/dashboard/shipments`);
      });
  }, [id, shipments]);

  const [showCreateModal, setShowCreateModal ] = useState(false);
  const [showChatPanel, setShowChatPanel ] = useState(false);

  const isMatchedSubPath = (subPath) => {
    const res = matchPath(props.routing.location?.pathname, {
      path: `/dashboard/shipments/:id/${subPath}`,
      exact: true,
      strict: false
    });
    return !!res;
  }

  const isAuthor = () => {
    const author = (currentShipment.collaborators || []).find(c => c.role == UserRoleOnShipment.Admin.value);
    return author?.userId == currentUser.id;
  }

  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setShowCreateModal: setShowCreateModal, currentShipment: currentShipment, isAuthor: isAuthor() });
    }
    return child;
  });

  const createChatChannel = () => {
    Modal.confirm({
      title: 'Create channel',
      content: 'Do you want to create a channel where you can communicate with collaborators?',
      okText: 'Create',
      cancelText: 'Cancel',
      onOk: () => {
        createChannelByShipment(id, (channel) => {
          props.routing.push(`/dashboard/channel`);
        });
      }
    });
  }

  const completeShipmentWrapper = () => {
    Modal.confirm({
      title: 'Mark this shipment as archived.',
      content: 'Are you sure you want to mark this shipment as archived?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        completeShipment(currentShipment.id)
          .then(() => {
            props.routing.push(`/dashboard/shipments`);
          });
      }
    });
  }

  const deleteShipmentWrapper = () => {
    Modal.confirm({
      title: 'Delete this shipment?',
      content: 'Are you sure you want to delete this shipment?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteShipment(currentShipment.id)
          .then(() => {
            props.routing.push(`/dashboard/shipments`);
          });
      }
    });
  }

  const menu = (
    <Menu>
      <Menu.Item key='complete' onClick={completeShipmentWrapper}>
        Archive this shipment
      </Menu.Item>

      <Menu.Item key='delete' onClick={deleteShipmentWrapper}>
        Delete this shipment
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='shipment'>
      {
        !!hideTopHeader ? (
          null
        ) : (
          <div className='shipment__nav'>
            <Link className='shipment__nav--back-btn' to='/dashboard/shipments'>
              <i className='fa-solid fa-boxes-packing' />
            </Link>

            <div className='shipment__nav--info'>
              <i className='fa-solid fa-angle-right' />
              <span>{ currentShipment.title }</span>
            </div>


            <div className='shipment__nav--navbar'>
              <div className='shipment__nav--left-navs'>
                <Link className={`shipment__nav--left-nav-item ${isMatchedSubPath('') ? 'current' : ''}`} to={`/dashboard/shipments/${id}`}>
                  Containers
                </Link>

                {/*
                <Link className={`shipment__nav--left-nav-item ${isMatchedSubPath('overview') ? 'current' : ''}`} to={`/dashboard/shipments/${id}/overview`}>
                  Overview
                </Link>
                */}

                {/*
                  <Link className={`shipment__nav--left-nav-item ${isMatchedSubPath('matters') ? 'current' : ''}`} to={`/dashboard/shipments/${id}/matters`}>
                    Matters
                  </Link>
                */}

                {/*
                <Link className={`shipment__nav--left-nav-item ${isMatchedSubPath('appointments') ? 'current' : ''}`} to={`/dashboard/shipments/${id}/appointments`}>
                  Appointments
                </Link>
                */}

                <Link className={`shipment__nav--left-nav-item ${isMatchedSubPath('files') ? 'current' : ''}`} to={`/dashboard/shipments/${id}/files`}>
                  Files
                </Link>
              </div>

              <div className='shipment__nav--right-navs'>
                <div className='shipment__nav--collaborators'>
                  {
                    (currentShipment.collaborators || []).map((c) => (
                      <div className='shipment__nav--collaborator-avatar' key={c.userId}>
                        <Avatar firstName={c.user.first_name} lastName={c.user.last_name} email={c.user.email} key={c.userId} />
                      </div>
                    ))
                  }

                  {
                    isAuthor() ? (
                      <div className='shipment__nav--collaborators-invite' onClick={ () => setShowInviteCollaborators(true) }>
                        Invite
                      </div>
                    ) : null
                  }
                </div>

                {
                  !!currentShipment.chatChannel ? (
                    <div className='shipment__nav--right-nav-item' onClick={ () => setShowChatPanel(true) }>
                      <i className='far fa-comment-dots' />
                    </div>
                  ) : (
                    isAuthor() ? (
                      <div className='shipment__nav--right-nav-item' onClick={ createChatChannel }>
                        <i className='far fa-comment-dots' />
                      </div>
                    ) : null
                  )
                }

                {
                  isAuthor() ? (
                    <Dropdown
                      overlay={menu}
                      trigger={['click']}
                      placement='bottomLeft'
                    >
                      <div className='shipment__nav--right-nav-item'>
                        <i className='fas fa-bars' />
                      </div>
                    </Dropdown>
                  ) : null
                }
              </div>
            </div>
          </div>
        )
      }

      <div className={`shipment__body ${hideTopHeader ? 'full-h' : ''}`}>
        { childrenWithProps }
      </div>

      <CreateShipmentModal
        goTo={ props.routing.push }
        visible={ showCreateModal }
        createShipment={ props.shipmentStore.createShipment }
        onCancel={ () => setShowCreateModal(false) }
      />

      <Drawer
        title={currentShipment.title}
        placement='right'
        className='shipment__drawer'
        width={780}
        open={showChatPanel}
        onClose={() => setShowChatPanel(false)}
      >
        <ChannelChat
          channel={channels[currentShipment.chatChannel?.id]}
          sendMessage={sendMessage}
          currentUserId={props.authStore.currentUser.id}
          readMessages={readMessages}
          fetchMoreMessages={fetchMoreMessages}
        />
      </Drawer>

      <InviteCollaborators
        visible={showInviteCollaborators}
        onCancel={() => setShowInviteCollaborators(false)}
        shipment={currentShipment}
        updateCollaboratorRole={updateCollaboratorRole}
        removeCollaborator={removeCollaborator}
        isAdmin={currentUser.id == admin?.userId}
        collaboratorRelations={collaboratorRelations}
        addCollaborators={addCollaborators}
      />
    </div>
  );
};

ShipmentLayout.propTypes = {
  hideTopHeader: PropTypes.bool,
};

export default inject("routing", "shipmentStore", "authStore", "channelStore", "collaboratorStore")(observer(ShipmentLayout));
