import axios from 'axios';

const rpgRequest = (url, method = 'get', data) => {
  return axios({
    url,
    method,
    withCredential: true,
    headers: {
      'Accept': 'application/json'
    },
    data
  });
};

export default rpgRequest;
