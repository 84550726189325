import { io } from "socket.io-client";

class WebSocketClient {
  constructor() {
    this.socket = null;
  }

  init() {
    this.socket = io();

    this.socket.onAny((event, ...args) => {
      console.log(event, args);
    });

    this.socket.on("connect_error", (err) => {
      console.error(err);
    });
  }
}

const webSocketClient = new WebSocketClient();
export default webSocketClient;
