import React from 'react';
import PropTypes from 'prop-types';

import { formatedChatDateTime, getMessagePreview } from '../../utils';

import './index.less';

const ChannelList = (props) => {
  const { channels, currentChannelId, currentUserId } = props;
  const { selectChannel, calculateMessagesCount } = props;

  return (
    <div className='channel-list'>
      {
        channels.map((channel) => {
          const unreadMessagesCount = calculateMessagesCount(currentUserId)(channel.id)('Unread');
          const mentionedMessagesCount = calculateMessagesCount(currentUserId)(channel.id)('Mention');
          const lastMessage = (channel.messages || [])[0];

          return (
            <div className={`channel-list__block ${currentChannelId == channel.id ? 'current' : ''}`} key={channel.id} onClick={() => selectChannel(channel.id, currentUserId)}>
              <div className='channel-list__block--icon'>
                <i className='fa-solid fa-globe' />
              </div>

              <div className='channel-list__block--content'>
                <div className='channel-list__block--name-block'>
                  <span className='name rpg-text-limit--one-line'>{channel.shipment.title}</span>
                  <span className='date'>
                    { formatedChatDateTime((channel.messages || [])[0]?.created_at, false) }
                  </span>
                </div>

                <div className='channel-list__block--last-msg'>
                  {
                    mentionedMessagesCount > 0 ? (
                      <span className='warnning rpg-text-limit--one-line'>You have been mentioned.</span>
                    ) : (
                      <span className='rpg-text-limit--one-line'>
                        {
                          lastMessage?.type == 'Attachment' ? (
                            'Uploaded attachment'
                          ) : null
                        }

                        {
                          lastMessage?.type == 'Text' ? (
                            getMessagePreview(lastMessage)
                          ) : null
                        }
                      </span>
                    )
                  }

                  {
                    !!unreadMessagesCount ? (
                      <label className='unread'>{ unreadMessagesCount }</label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

ChannelList.propTypes = {
  channels: PropTypes.array.isRequired,
  currentChannelId: PropTypes.string,
  selectChannel: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  calculateMessagesCount: PropTypes.func.isRequired,
}

export default ChannelList;
