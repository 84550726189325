import { computed, makeAutoObservable, observable } from "mobx";
import { message } from 'antd';

import rpgRequest from '../lib/rpgRequest.js';

class CollaboratorStore {
  @observable collaboratorRelations = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchAllCollaboratorRelations = () => {
    rpgRequest('/api/collaboration_relationships')
      .then((res) => {
        const relations = res.data.map((u) => u.member);
        this.collaboratorRelations = relations;
        // setCurrentMember(relations[0]);
      })
      .catch((err) => {
        message.error('Server error', 5);
      });
  }

  deleteCollaboratorRelation = (memberId, callback = () => {}) => {
    rpgRequest(`/api/collaboration_relationships/${memberId}`, 'delete')
      .then((res) => {
        this.collaboratorRelations = this.collaboratorRelations.filter(m => m.id != res.data.memberId);
        callback(res.data);
      })
      .catch((err) => {
        message.error('Server error', 5);
      });
  }
}

export default CollaboratorStore;
