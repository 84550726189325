import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Form, Input, Upload, message } from 'antd';
import debounce from 'lodash.debounce';
import sortBy from 'lodash.sortby';

import { readXLSX, readCSV } from '../../utils/file_reader.js';

import './index.less';

const AddContainerModal = (props) => {
  let inputContainerNum, inputBol;

  const { shipmentId, visible, carriers, onOk, onCancel, searchCarrier } = props;

  const [rowsInfo, setRowsInfo] = useState([]);

  const [invalidNums, setInvalidNums] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validateRes, setValidateRes] = useState({});

  const [form] = Form.useForm();

  const setInputValue = (type) => (e) => {
    if (type == 'bol') {
      inputBol = e.target.value.replace(/\s/g, '').trim().toUpperCase();
      form.setFieldValue('bol', inputBol);
    }

    if (type == 'containerNum') {
      inputContainerNum = e.target.value.replace(/\s/g, '').trim().toUpperCase();
      form.setFieldValue('containerNum', inputContainerNum);
    }

    searchCarrier(inputContainerNum, inputBol)
      .then((res) => {
        form.setFieldValue('scacCode', !res.data?.scac || `${res.data?.scac}[${res.data?.carrier_abbrev}]`);
      });
  }

  const validateSubmit = (containerNum, bol, scacCode) => {
    let isValid = true;
    setValidateRes({});

    if (!!containerNum) {
      if (!!bol) {
        if (!/^[a-zA-Z]{4}([a-zA-Z0-9])*$/.test(bol)) {
          setValidateRes({...validateRes, bol: 'Format is invalid.'});
          isValid = false;
        }
      }

      if (!/^[a-zA-Z]{4}\d{7}$/.test(containerNum)) {
        setValidateRes({...validateRes, containerNum: 'Format is invalid.'});
        isValid = false;
      }
    } else {
      if (!!bol) {
        if (!/^[a-zA-Z]{4}([a-zA-Z0-9])*$/.test(bol)) {
          setValidateRes({...validateRes, bol: 'Format is invalid.'});
          isValid = false;
        }
      } else {
        setValidateRes({...validateRes, bol: 'required'});
        isValid = false;
      }
    }

    if (!scacCode) {
      setValidateRes({ ...validateRes, scacCode: 'required' })
      isValid = false;
    }

    if (!!isValid) {
      setValidateRes({});
    }
    return isValid;
  }

  const addContainer = () => {
    if (rowsInfo.length > 0) {
      onOk(shipmentId, rowsInfo)
        .then(() => {
          setIsLoading(false);
          onCancel();
        });
      return;
    }

    const { containerNum, bol, scacCode } = form.getFieldsValue();

    if (!validateSubmit(containerNum, bol, scacCode)) {
      return;
    }

    let formatScacCode = scacCode.replace(/\[.+\]/, '');
    const params = [{
      bol,
      ctr: containerNum,
      scac: formatScacCode
    }];

    setIsLoading(true);
    onOk(shipmentId, params)
      .then(() => {
        setIsLoading(false);
        onCancel();
      });
  }

  const closeModal = () => {
    onCancel();
  }

  const clearUpload = () => {
    setRowsInfo([]);
    setInvalidNums([]);
  }

  const readFile = async (file) => {
    let fileType = file.type;
    if (!fileType.includes("text/csv") && !fileType.includes("ms-excel") && !fileType.includes("openxmlformats") && !fileType.includes("text/tab-separated-values")) {
      message.error('Invalid type of uploaded file.');
      return;
    }

    let res;
    try {
      if (fileType.includes("text/csv") || fileType.includes("ms-excel") || (fileType.includes("text/tab-separated-values"))) {
        res = await readCSV(file);
        res.shift(); // remove the first line of column names.
      }

      if (fileType.includes("openxmlformats")) {
        const [mainData, mappingData] = await readXLSX(file, ["BOL", "CTR", "Carrier name (optional)"], ["Carrier Code", "Carrier Name (Abbreviated)", "Carrier Name"]);
        const scacMapping = mappingData.reduce((acc, row) => {
          acc[row[2]] = row[0];
          return acc;
        }, {});

        res = mainData.map((row) => {
          return [row[0], row[1], scacMapping[row[2]]];
        });
        res.shift(); // remove the first line of column names.
      }
    } catch(e) {
      console.error(e);
      message.error("Can't parse the uploaded file.");
      return;
    }

//     console.error(res);
//     return;

    const uploadedInfo = [];
    const uploadedErrors = [];

    for (const row of res) {
      if (!!row[0]) {
        if (!/^[a-zA-Z]{4}.+$/.test(row[0])) {
          uploadedErrors.push(row[0]);
          continue;
        }

        if (!!row[1] && !/^[a-zA-Z]{4}\d{7}$/.test(row[1])) {
          uploadedErrors.push(row[1]);
          continue;
        }

        uploadedInfo.push({ bol: row[0]?.replace(/\s|\/|\\/g, ''), ctr: row[1]?.replace(/\s|\/|\\/g, ''), scac: row[2]?.replace(/\s|\/|\\/g, '') });
        continue;
      }

      if (!!row[1]) {
        if (!/^[a-zA-Z]{4}\d{7}$/.test(row[1])) {
          uploadedErrors.push(row[1]);
          continue;
        }

        uploadedInfo.push({ ctr: row[1]?.replace(/\s|\/|\\/g, ''), scac: row[2]?.replace(/\s|\/|\\/g, '') });
        continue;
      }
    };

    setRowsInfo(uploadedInfo);
    setInvalidNums(uploadedErrors);
  }

  return (
    <div>
      <Modal
        className='add-container-modal'
        title='Add container'
        width={720}
        open={ visible }
        onOk={ addContainer }
        onCancel={ closeModal }
        destroyOnClose={true}
        okText='Track Now'
      >
        {/*
        <Select
          mode="tags"
          className="add-container-modal__input"
          style={{
            width: '100%',
          }}
          value={containerNums}
          placeholder="Paste your containers here"
          showSearch={false}
          dropdownStyle={{display: 'none'}}
          tokenSeparators={[" ", ","]}
          onChange={setContainerNums}
        />
        */}

        <div className='add-container-modal__body'>
          <div className='add-container-modal__section'>
            <Form
              preserve={false}
              className='add-container-modal__form'
              layout='vertical'
              form={form}
              size='large'
            >
              <Form.Item
                label='Container Number'
                size='large'
                name='containerNum'
                validateStatus={!!validateRes['containerNum'] && 'error'}
                help={validateRes['containerNum']}
              >
                <Input
                  onChange={debounce(setInputValue('containerNum'), 300)}
                />
              </Form.Item>

              <Form.Item
                label='Bill of Lading'
                size='large'
                name='bol'
                validateStatus={!!validateRes['bol'] && 'error'}
                help={validateRes['bol']}
              >
                <Input
                  onChange={debounce(setInputValue('bol'), 300)}
                />
              </Form.Item>

              <Form.Item
                label='Carrier'
                size='large'
                name='scacCode'
                validateStatus={!!validateRes['scacCode'] && 'error'}
                help={validateRes['scacCode']}
              >
                <Select
                  showSearch
                  size='large'
                  options={
                    sortBy(carriers.map(c => ({ key: `${c.abbreviation}_${c.scac_code}`, value: `${c.scac_code}[${c.abbreviation}]`, label: `(${c.abbreviation}) ${c.name}` })), 'label')
                  }
                  optionFilterProp='label'
                />
              </Form.Item>
            </Form>
          </div>

          <div className='add-container-modal__section'>
            <div className='add-container-modal__upload--header'>
              <label>Batch import</label>
              <a onClick={ clearUpload }>Clear</a>
              <a href='/static/package/cit_template.xlsx' target='_blank'>Template.xlsx</a>
            </div>

            <div className='add-container-modal__upload--main'>
              {
                rowsInfo.length > 0 || invalidNums.length > 0 ? (
                  <div className='add-container-modal__upload--uploaded-info'>
                    <p>Extract from uploaded file:</p>
                    <p>{rowsInfo.length} rows.</p>
                    <p>Invalid bols and container numbers:</p>
                    <p>
                      {
                        invalidNums.length > 0 ? (
                          invalidNums.join(', ')
                        ) : (
                          'none'
                        )
                      }
                    </p>
                  </div>
                ) : (
                  <Upload
                    beforeUpload={readFile}
                    showUploadList={false}
                  >
                    <div className='add-container-modal__upload--placeholder'>
                      <p>
                        <i className='fa-solid fa-cloud-arrow-up' />
                      </p>
                      <p>
                        Fill out the <b>Template.xlsx</b> and upload to track.
                      </p>
                    </div>
                  </Upload>
                )
              }
            </div>
          </div>
        </div>
      </Modal>

      {
        isLoading ? (
          <div className='loading__absolute-container'>
            <div className='loading__shadow'>
              <div className='loading__placeholder'>
                <i className="fas fa-spinner" />
              </div>
            </div>
          </div>
        ) : null
      }
    </div>
  );
};

AddContainerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  shipmentId: PropTypes.string,
  carriers: PropTypes.array.isRequired,
  searchCarrier: PropTypes.func.isRequired,
};

export default AddContainerModal;
