import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Dropdown, Menu, Modal } from 'antd';
import moment from 'moment';
import get from 'lodash.get';
import { TerminalLinks } from '../../utils/constants';

import AppointmentInfoModal from '../AppointmentInfoModal';

import './index.less';

const AppointmentsTable = (props) => {
  const columns = [
    {
      title: 'Appointment ID',
      dataIndex: 'readableId',
      key: 'readableId',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        return (
          <div className='appointments-table__status'>
            <p>
              <span>{value}</span>
            </p>
          </div>
        );
      }
    },
    {
      title: 'Type',
      align: 'center',
      key: 'type',
      render: (record) => {
        return 'Pick Import';
      }
    },
    {
      title: 'Container Info',
      align: 'center',
      dataIndex: 'unit_nbr',
      key: 'unit_nbr',
      render: (value, record) => (
        <div className='appointments-table__unitinfo'>
          <p className='id'>{ record.containerInfo?.containerNum }</p>
          <p>
            { record.containerInfo?.carrier?.scac } - { record.containerInfo?.containerSize }{ record.containerInfo?.containerType }
          </p>
        </div>
      )
    },
    {
      title: 'APPT Time',
      align: 'center',
      key: 'appt_time',
      render: (record) => {
        if (['GateOut', 'EmptyReturned', 'NotFound'].includes(record.status)) {
          return (
            <div className='containers-table__pickup-schedule'>-</div>
          );
        }

        const terminalCode = record.terminalCode;
        const terminalInfo = TerminalLinks[terminalCode];
        return (
          <div className='containers-table__pickup-schedule'>
            { terminalInfo?.name || "-" }

            <div style={{fontWeight: 400, fontSize: '14px', color: '#797979'}}>
              <p style={{margin: 0}}>{moment(record.pickUpAt).format('ddd, MMM DD')}</p>
              <p style={{margin: 0}}>{moment(record.pickUpAt).format('HH:mm z')}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Reference',
      dataIndex: 'files',
      key: 'files',
      align: 'center',
      render: (content) => (
        content?.length > 0 ? (
          <Dropdown
            trigger={['hover']}
            placement='top'
            overlay={
              <Menu
                items={
                  content.map((c) => ({
                    key: c.path,
                    label: c.name,
                    onClick: () => window.open(`${window.location.origin}/${c.path}`, '_blank')
                  }))
                }
              />
            }
          >
            <a>View</a>
          </Dropdown>
        ) : (
          '-'
        )
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => {
        if (record.authorId != currentUser.id) {
          return '-';
        }

        return (
          <div className='appointments-table__actions'>
            <i className='fa-regular fa-pen-to-square' onClick={() => setCurrentAppointment(record)} />
            <i className='fa-solid fa-trash-can' onClick={() => onRemove(record)} />
          </div>
        );
      }
    }
  ];

  const { appointments, isLoading, onDelete, currentUser, updatePickUpAppointment } = props;
  const [currentAppointment, setCurrentAppointment] = useState(null);

  const onRemove = (record) => {
    Modal.confirm({
      title: 'Delete appointment',
      content: 'Are you sure you want to delete this appointment?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        onDelete(record.shipmentId, record.key);
      }
    });
  }

  return (
    <div>
      <Table
        className='appointments-table'
        loading={isLoading}
        columns={columns}
        dataSource={appointments.map((r) => ({key: r.id, readableId: r.readableId, files: r.files, authorId: r.authorId, shipmentId: r.shipmentId, pickUpAt: r.pick_up_at, status: r.container.status, containerInfo: r.container.basic_info}))}
      />

      <AppointmentInfoModal
        visible={!!currentAppointment}
        cancel={() => setCurrentAppointment(null)}
        appointment={currentAppointment}
        updatePickUpAppointment={updatePickUpAppointment}
      />
    </div>
  );
};

AppointmentsTable.propTypes = {
  appointments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default AppointmentsTable;
