import React from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import { Form, Input, Button } from 'antd';

import './index.less';

const SignUp = (props) => {
  const { authStore } = props;
  const [form] = Form.useForm();

  const registerUser = (data) => {
    authStore.registerUser(data);
  }

  const verifyCode = (data) => {
    authStore.verifyEmailByCode(data);
  }

  if (!!authStore.currentUser && !authStore.currentUser.email_confirmed) {
    return (
      <div className='signup'>
        <div className='signup-layout__title'>Check your email</div>
        <div className='signup-layout__subtitle'>Verification code has been sent to <a>{ authStore.currentUser.email }</a>，Please check it and enter verification code received in email to complete registration</div>

        <Form
          form={form}
          className='signup-layout__form'
          layout="vertical"
          size='large'
          onFinish={verifyCode}
        >
          <Form.Item
            name='code'
            rules={[{ required: true, message: 'Please input your verification code!' }]}
            label={<span style={{color: '#1F3858', fontSize: '16px'}}>Verification Code</span>}
          >
            <Input placeholder='Verification Code' />
          </Form.Item>

          <div className='signup-layout__link-button' onClick={ authStore.sendVerifyEmail }>
            Resend Verification Code
          </div>

          <div className='signup-layout__20vh-div' />

          <Form.Item>
            <Button htmlType='submit' className='signup-layout__submit-button'>
              Create Account
            </Button>
          </Form.Item>
        </Form>

        <div className='signup-layout__text-info'>
          Already have an account? <Link className='signup-layout__link' to='/signin'>Log in</Link>
        </div>
      </div>
    );
  }

  return (
    <div className='signup'>
      <div className='signup-layout__title'>Create your Account</div>
      <div className='signup-layout__subtitle'>60 day Free Trial for New Companies!</div>

      <Form
        form={form}
        className='signup-layout__form'
        layout="vertical"
        size='large'
        onFinish={registerUser}
      >
        <div className='signup__inline-block'>
          <Form.Item
            name='first_name'
            rules={[
              { required: true, message: 'Please input your first name!' },
              { type: 'string', min: 2, message: 'At least 2 characters required.' },
              { type: 'string', max: 10, message: 'Cannot exceed 10 characters!' },
              { type: 'string', whitespace: true, message: 'Cannot only contain whitespaces.' }
            ]}
            label={<span style={{color: '#1F3858', fontSize: '16px'}}>First Name</span>}
          >
            <Input placeholder='First Name' />
          </Form.Item>

          <Form.Item
            name='last_name'
            rules={[
              { required: true, message: 'Please input your last name!' },
              { type: 'string', min: 2, message: 'At least 2 characters required.' },
              { type: 'string', max: 10, message: 'Cannot exceed 10 characters!' },
              { type: 'string', whitespace: true, message: 'Cannot only contain whitespaces.' }
            ]}
            label={<span style={{color: '#1F3858', fontSize: '16px'}}>Last Name</span>}
          >
            <Input placeholder='Last Name' />
          </Form.Item>
        </div>

        <Form.Item
          name='email'
          rules={[
            { required: true, message: 'Please input your email!' },
            { pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/, message: 'Email does not match the format.' }
          ]}
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Email</span>}
        >
          <Input placeholder='Email' />
        </Form.Item>

        <Form.Item
          name='phone'
          rules={[{ required: true, message: 'Please input your phone number!' }]}
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Phone</span>}
        >
          <Input placeholder='Phone' />
        </Form.Item>

        <Form.Item
          name='password'
          rules={[
            { required: true, message: 'Please input your password!' },
            { type: 'string', min: 8, message: 'At least 8 characters required.' },
            { type: 'string', max: 24, message: 'Cannot exceed 24 characters!' },
          ]}
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Password</span>}
        >
          <Input.Password placeholder='Password' />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' className='signup-layout__submit-button'>
            Create Account
          </Button>
        </Form.Item>
      </Form>

      <div className='signup-layout__text-info'>
        Already have an account? <Link className='signup-layout__link' to='/signin'>Log in</Link>
      </div>
    </div>
  );
}

export default inject('authStore')(observer(SignUp));
