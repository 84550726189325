import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DatePicker } from 'antd';

import Avatar from '../Avatar';
import InviteCollaborators from '../InviteCollaborators';

import './index.less';

const ShipmentDetailBlock = (props) => {
  const [showInviteCollaborators, setShowInviteCollaborators] = useState(false);

  const { isAuthor, shipment, currentUser, updateCollaboratorRole, removeCollaborator, collaboratorRelations, updateETA, addCollaborators } = props;
  const admin = (shipment?.collaborators || []).find(c => c.role == 'Admin');

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  return (
    <div className='shipment-detail-block'>
      <div className='shipment-detail-block__top'>
        <div className='shipment-detail-block__top--name'>
          <i className='fa-solid fa-boxes-packing' />
          <div className='shipment-detail-block__top--name-info'>
            <p className='name'>{shipment.title}</p>
            <p className='date'>
              Created at {moment(shipment.created_at).format('MM/DD/YYYY')} by {admin?.user.first_name}
            </p>
          </div>
        </div>

        <div className='shipment-detail-block__top--datetime'>
          {
            isAuthor ? (
              <DatePicker
                className='shipment-select-eta'
                inputReadOnly={true}
                disabledDate={disabledDate}
                value={moment(shipment.eta)}
                onChange={(m) => updateETA(shipment.id, m.toISOString())}
              />
            ) : (
              <i className='fa-solid fa-calendar' />
            )
          }
          <div className='shipment-detail-block__top--datetime-info'>
            <p className='date'>{moment(shipment.eta).format('MMM DD')}</p>
            <p>ETA</p>
          </div>
        </div>
      </div>

      <div className='shipment-detail-block__desc'>{shipment.description}</div>

      <div className='shipment-detail-block__members'>
        <div className='shipment-detail-block__members--label'>Collaborators</div>
        <div className='shipment-detail-block__members--avatars-list'>
          {
            (shipment.collaborators || []).map((c) => (
              <Avatar firstName={c.user.first_name} lastName={c.user.last_name} email={c.user.email} key={c.userId} />
            ))
          }

          {
            isAuthor ? (
              <div className='shipment-detail-block__members--add-member' onClick={() => setShowInviteCollaborators(true)}>
                <i className='fa-solid fa-plus' />
              </div>
            ) : null
          }
        </div>
      </div>

      <InviteCollaborators
        visible={showInviteCollaborators}
        onCancel={() => setShowInviteCollaborators(false)}
        shipment={shipment}
        updateCollaboratorRole={updateCollaboratorRole}
        removeCollaborator={removeCollaborator}
        isAdmin={currentUser.id == admin?.userId}
        collaboratorRelations={collaboratorRelations}
        addCollaborators={addCollaborators}
      />
    </div>
  );
}

ShipmentDetailBlock.propTypes = {
  shipment: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  updateCollaboratorRole: PropTypes.func.isRequired,
  removeCollaborator: PropTypes.func.isRequired,
  updateETA: PropTypes.func.isRequired,
  collaboratorRelations: PropTypes.array.isRequired,
  addCollaborators: PropTypes.func.isRequired,
};

export default ShipmentDetailBlock;
