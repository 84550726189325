import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { Table, Modal, Dropdown, Menu, message } from 'antd';
import moment from 'moment';
import chooseFiles from 'choose-files';

import Avatar from '../../components/Avatar';
import { FileCategories, UserRoleOnShipment } from '../../utils/constants.js';

import './index.less';

const ShipmentFiles = (props) => {
  const { authStore } = props;
  const { files, isLoading, fetchAllFiles, deleteFile, uploadFiles } = props.fileStore;

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'File Type',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Upload Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (content) => moment(content).format('MM/DD/YYYY HH:mm')
    },
    {
      title: 'Upload By',
      dataIndex: 'operator',
      key: 'operator',
      render: (content) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar
              firstName={content.first_name}
              lastName={content.last_name}
              email={content.email}
            />
            <span style={{marginLeft: '6px'}}>{ content.first_name }</span>
          </div>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <div style={{color: '#9A9A9A', fontSize: '20px'}}>
          <i className='fa-regular fa-file-lines' style={{cursor: 'pointer', marginRight: '12px'}} onClick={openNewTab(record.path)} />
          {
            isAuthor() ? (
              <i className='fa-solid fa-trash-can' style={{cursor: 'pointer'}} onClick={removeFile(record.shipmentId, record.id)} />
            ) : null
          }
        </div>
      )
    }
  ];

  const menu = (
    <Menu
      items={
        Object.keys(FileCategories).map((k) => ({
          key: FileCategories[k].value,
          label: FileCategories[k].label,
          onClick: () => chooseFiles(uploadAttachment(FileCategories[k].value))
        }))
      }
    />
  );


  const openNewTab = (path) => () => {
    window.open(`${window.location.origin}/${path}`, '_blank');
  }

  const { currentShipment } = props;

  const isAuthor = () => {
    const author = (currentShipment.collaborators || []).find(c => c.role == UserRoleOnShipment.Admin.value);
    return author?.userId == authStore.currentUser.id;
  }

  useEffect(() => {
    if (!!currentShipment.id) {
      fetchAllFiles(currentShipment.id);
    }
  }, [currentShipment]);

  const removeFile = (shipmentId, fileId) => () => {
    Modal.confirm({
      title: 'Delete File',
      content: 'Are you sure you want to delete this file?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteFile(shipmentId, fileId);
      }
    });
  }

  const uploadAttachment = (category) => (uploadingFiles) => {
    if (uploadingFiles.length > 10) {
      message.error('You can only upload 10 files at a time.', 5);
      return;
    }

    const errors = [];
    uploadingFiles.forEach((f) => {
      if (f.size / 1048576 > 20) {
        errors.push(`The file's size can not be large than 20MB, "${f.name}".`);
      }

      if (f.type.includes("exe")) {
        errors.push(`Can not upload ${f.type} file, "${f.name}".`);
      }
    });

    if (errors.length > 0) {
      errors.forEach((err) => {
        message.error(err);
      });
      return;
    }

    uploadFiles({
      files: uploadingFiles.map((file) => {
        const splitFileName = file.name.split('.');
        const extension = splitFileName.pop();
        const name = splitFileName.join('.');

        return {
          content: file,
          extension,
          name,
        };
      }),
      category,
      shipmentId: currentShipment.id
    });
  }

  return (
    <div className='shipment-files'>
      <div className='shipment-files__header'>
        File

        <Dropdown
          trigger={['click']}
          overlay={menu}
          placement='bottom'
        >
          <div className='shipment-files__header--upload-btn'>
            <i className='fa-solid fa-arrow-up-from-bracket' />
            Upload
          </div>
        </Dropdown>
      </div>

      <div className='shipment-files__body'>
        <Table
          className='shipment-files-table'
          loading={isLoading}
          columns={columns}
          dataSource={files}
        />
      </div>
    </div>
  );
};

export default inject("authStore", "fileStore")(observer(ShipmentFiles));
