import { makeAutoObservable, observable, computed } from 'mobx';
import { message } from 'antd';
import rpgRequest from '../lib/rpgRequest.js';

const SOCKET_TYPE = 'FILE';

class FileStore {
  @observable files = [];
  @observable isLoading = false;

  constructor(socketClient) {
    this.socket = socketClient.socket;

    makeAutoObservable(this);
  }

  fetchAllFiles = (shipmentId) => {
    this.isLoading = true;
    return rpgRequest(`/api/shipments/${shipmentId}/files`)
      .then((res) => {
        this.files = res.data;
        this.isLoading = false;
      })
      .catch((err) => {
        message.error('Server error', 5);
        this.isLoading = false;
      });
  }

  uploadFiles = (params) => {
    this.socket.emit(SOCKET_TYPE, 'UPLOAD_FILES', params, (data) => {
      this.files = [...data, ...this.files];
    });
  }

  deleteFile = (shipmentId, fileId) => {
    this.isLoading = true;
    return rpgRequest(`/api/shipments/${shipmentId}/files/${fileId}`, 'delete')
      .then((res) => {
        this.files = this.files.filter(f => f.id != fileId);
        this.isLoading = false;
      })
      .catch((err) => {
        message.error('Server error', 5);
        this.isLoading = false;
      });
  }
}

export default FileStore;
