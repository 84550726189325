import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Table, Input, message } from 'antd';
import rpgRequest from '../../lib/rpgRequest.js';

import './index.less';

const AdminUsers = (props) => {
  const columns = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.first_name} ${record.last_name}`;
      }
    },
    {
      title: 'Email Confirmed',
      key: 'email_confirmed',
      dataIndex: 'email_confirmed',
      render: (confirmed) => {
        return confirmed ? 'Yes' : 'No';
      }
    },
    {
      title: 'Phone Number',
      key: 'phone_number',
      dataIndex: 'phone_number'
    },
    {
      title: 'Shipments',
      key: 'shipments',
      render: (record) => {
        return <Link to={`/dashboard/admin-shipments?collaborator=${record.email}`}>{ record.shipments.length }</Link>;
      }
    },
    {
      title: 'Containers',
      key: 'containers',
      render: (record) => {
        return <Link to={`/dashboard/admin-containers?creator=${record.email}`}>{ record.containers.length }</Link>;
      }
    },
    {
      title: 'Created At',
      key: 'created_at',
      render: (record) => {
        return moment(record.created_at).format('MM/DD/YYYY HH:mm')
      }
    }
  ];

  const [filter, setFilter] = useState('');
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    rpgRequest('/api/admin/users')
      .then((res) => {
        setDataSource(res.data);
      });
  }, []);

  return (
    <div className='admin-users'>
      <Input
        size='large'
        style={{marginBottom: '16px', width: '300px'}}
        placeholder='Input Name, Phone Number or Email'
        onChange={(e) => setFilter(e.target.value.toLowerCase())}
      />

      <Table
        rowKey={row => row.id}
        dataSource={
          dataSource.filter((r) => {
            return `${r.first_name} ${r.last_name}`.toLowerCase().includes(filter) || r.email.toLowerCase().includes(filter) || r.phone_number.toLowerCase().includes(filter);
          })
        }
        columns={columns}
      />
    </div>
  );
}

export default AdminUsers
