import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import './index.less';

const schema = Joi.object({
  name: Joi.string().min(2).max(20).required(),
  type: Joi.string().required(),
  phone_number: Joi.string().required(),
  fax_number: Joi.string().allow('', null),
  scac_code: Joi.string().allow('', null),
  us_dot_permit_no: Joi.string().allow('', null),
  motor_carrier_permit_no: Joi.string().allow('', null),
  detailed_address: Joi.string().required(),
  company_description: Joi.string().required()
});

const CompanyForm = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: joiResolver(schema),
  });

  const { onSubmit, onCancel } = props;

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onCancel();
  }

  return (
    <div className='company-form'>
      <form onSubmit={handleSubmit(d => onSubmit(d))}>
        <div className='company-form__block'>
          <label htmlFor='name'>
            *Company Name
          </label>

          <input className={`${errors.name ? 'error' : ''}`} {...register('name')} />

          {errors.name && <p>{errors.name.message}</p>}
        </div>

        <div className='company-form__block'>
          <label htmlFor='type'>
            *Company Type
          </label>

          <select className={`${errors.type ? 'error' : ''}`} {...register('type')}>
            <option value='test'>test</option>
          </select>

          {errors.type && <p>{errors.type.message}</p>}
        </div>

        <div className='company-form__block inline'>
          <div className='company-form__block--column'>
            <label htmlFor='phone_number'>
              *Phone Number
            </label>

            <input className={`${errors.phone_number ? 'error' : ''}`} {...register('phone_number')} />

            {errors.phone_number && <p>{errors.phone_number.message}</p>}
          </div>

          <div className='company-form__block--column'>
            <label htmlFor='fax_number'>
              Fax Number
            </label>

            <input className={`${errors.fax_number ? 'error' : ''}`} {...register('fax_number')} />

            {errors.fax_number && <p>{errors.fax_number.message}</p>}
          </div>
        </div>

        <div className='company-form__block inline'>
          <div className='company-form__block--column'>
            <label htmlFor='scac_code'>
              SCAC Code
            </label>

            <input className={`${errors.scac_code ? 'error' : ''}`} {...register('scac_code')} />

            {errors.scac_code && <p>{errors.scac_code.message}</p>}
          </div>

          <div className='company-form__block--column'>
            <label htmlFor='us_dot_permit_no'>
              US DOT Permit No
            </label>

            <input className={`${errors.us_dot_permit_no ? 'error' : ''}`} {...register('us_dot_permit_no')} />

            {errors.us_dot_permit_no && <p>{errors.us_dot_permit_no.message}</p>}
          </div>
        </div>

        <div className='company-form__block'>
          <label htmlFor='motor_carrier_permit_no'>
            Motor Carrier Permit No
          </label>

          <input className={`${errors.motor_carrier_permit_no ? 'error' : ''}`} {...register('motor_carrier_permit_no')} />

            {errors.motor_carrier_permit_no && <p>{errors.motor_carrier_permit_no.message}</p>}
        </div>

        <div className='company-form__block'>
          <label htmlFor='detailed_address'>
            *Detailed address
          </label>

          <input className={`${errors.detailed_address ? 'error' : ''}`} {...register('detailed_address')} />

          {errors.detailed_address && <p>{errors.detailed_address.message}</p>}
        </div>

        <div className='company-form__block'>
          <label htmlFor='company_description'>
            *Company Description
          </label>

          <textarea className={`${errors.company_description ? 'error' : ''}`} {...register('company_description')} />

          {errors.company_description && <p>{errors.company_description.message}</p>}
        </div>

        <div className='company-form__btns-group'>
          <button className='register'>Register Now</button>

          <button className='cancel' onClick={ onCancel }>cancel</button>
        </div>
      </form>
    </div>
  );
};

CompanyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CompanyForm;
