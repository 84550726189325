import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import { Input, Modal, message } from 'antd';
import debounce from 'lodash.debounce';

import Avatar from '../../components/Avatar';

import rpgRequest from '../../lib/rpgRequest.js';

import './index.less';

const CollaboratorsList = (props) => {
  const { collaboratorRelations, deleteCollaboratorRelation } = props.collaboratorStore;

  const [keywords, setKeywords] = useState('');
  const search = (e) => {
    setKeywords(e.target.value);
  }

  const [currentMember, setCurrentMember] = useState({});

  const deleteRelation = () => {
    Modal.confirm({
      title: 'Remove collaborator',
      content: 'Are you sure you want to remove this collaborator?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteCollaboratorRelation(currentMember.id, () => setCurrentMember({}));
      }
    });
  }

  const members = !!keywords.trim() ? (
    collaboratorRelations.filter(m => (m.first_name + ' ' + m.last_name).toLowerCase().includes(keywords.toLowerCase()) || m.email.toLowerCase().includes(keywords.toLowerCase()))
  ) : collaboratorRelations;

  if (collaboratorRelations.length == 0) {
    return (
      <div className='channel-container'>
        <div className='channel-container__placeholder'>
          <span>You do not have any collaborators.</span>
        </div>
      </div>
    );
  }

  return (
    <div className='collaborators-list'>
      <div className='collaborators-list__left'>
        <Input
          size='large'
          placeholder='search name or email'
          prefix={<i className='fa-solid fa-search' />}
          onChange={ debounce(search, 500) }
        />

        <div className='collaborators-list__user-list'>
          {
            members.map((member) => (
              <div className={`collaborators-list__user-block ${member.id == currentMember.id ? 'current' : ''}`} key={member.id} onClick={() => setCurrentMember(member)}>
                <div className='collaborators-list__user-block--avatar'>
                  <Avatar size='w-42' firstName={ member.first_name } lastName={ member.last_name } email={ member.email } />
                </div>

                <div className='collaborators-list__user-block--info'>
                  <div className='collaborators-list__user-block--name rpg-text-limit--one-line'>{ member.first_name } { member.last_name }</div>
                  <div className='collaborators-list__user-block--email rpg-text-limit--one-line'>{ member.email }</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div className='collaborators-list__right'>
        <div className='collaborators-list__user-bg' style={{backgroundImage: 'url(/static/images/user-bg.png)'}} />
        {
          currentMember.email ? (
            <div className='collaborators-list__user-info'>
              <Avatar size='w-128' email={currentMember.email || ''} firstName={currentMember.first_name} lastName={currentMember.last_name} />
              <div className='collaborators-list__user-info--name'>{currentMember.first_name} {currentMember.last_name}</div>
              <div className='collaborators-list__user-info--email'>{currentMember.email}</div>
            </div>
          ) : null
        }

        {
          currentMember.email ? (
            <div className='collaborators-list__right--delete-btn' onClick={ deleteRelation }>
              <i className='fa-solid fa-trash-can' />
            </div>
          ) : null
        }
      </div>
    </div>
  );
};

export default inject('collaboratorStore')(observer(CollaboratorsList));
