import React, { useState } from 'react';
import debounce from 'lodash.debounce';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import { Form, Input, Button } from 'antd';

import './index.less';

const JoinCompany = (props) => {
  const { authStore } = props;
  const [companyInfo, setCompanyInfo] = useState({});

  const [form] = Form.useForm();

  const requestJoinCompany = (data) => {
    if (!companyInfo.id) {
      form.setFields([{
        name: 'code',
        errors: ['Valid company code is required.']
      }]);
      return;
    }

    authStore.joinCompany(companyInfo.id);
  }

  const findCompany = (e) => {
    const code = e.target.value;
    if (!code) {
      setCompanyInfo({});
      return;
    }

    authStore.findCompany(code)
      .then((data) => {
        const company = data?.list && data?.list[0];
        if (company) {
          setCompanyInfo({
            name: company.name,
            id: company._id,
          });
          return;
        }
        setCompanyInfo({});
      });
  }

  return (
    <div className='join-company'>
      <div className='signin-layout__title'>Join a Company</div>

      <Form
        form={form}
        className='signin-layout__form'
        layout="vertical"
        size='large'
        onFinish={ requestJoinCompany }
      >
        <Form.Item
          name='code'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Company Code</span>}
          rules={[{ required: true, message: 'Valid company code is required.' }]}
        >
          <Input placeholder='Company Code' onChange={ debounce(findCompany, 500) } />
        </Form.Item>

        <Form.Item
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Company Name</span>}
        >
          <Input placeholder='Company Name' disabled value={companyInfo.name} />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' className='signin-layout__submit-button'>
            Join Now
          </Button>
        </Form.Item>
      </Form>

      <div className='signin-layout__text-info'>
        <Link to='/company/new'>Create a compnay</Link>
      </div>
    </div>
  );
}

export default inject('authStore')(observer(JoinCompany));
