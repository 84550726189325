import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Button } from 'antd';

const ResetPassword = (props) => {
  const { email, resetPassword } = props;
  const [form] = Form.useForm();

  return (
    <div className='reset-password'>
      <div className='signin-layout__title with-subtitle'>Reset your password</div>
      <div className='signin-layout__subtitle'>An email with the verification code will be send to you resgistered email address</div>

      <Form
        form={form}
        className='signin-layout__form'
        layout="vertical"
        size='large'
        onFinish={ resetPassword }
        initialValues={{ email }}
      >
        <Form.Item label={<span style={{color: '#1F3858', fontSize: '16px'}}>Email</span>}>
          <Form.Item
            name='email'
            noStyle
            rules={[{ required: true }]}
          >
            <Input placeholder='Email' disabled />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name='code'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>Verification Code</span>}
          disabled
          rules={[{ required: true }]}
        >
          <Input placeholder='Verification Code' />
        </Form.Item>

        <Form.Item
          name='password'
          label={<span style={{color: '#1F3858', fontSize: '16px'}}>New password</span>}
          disabled
          rules={[{ required: true }]}
        >
          <Input.Password placeholder='New Password' />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' className='signin-layout__submit-button'>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

ResetPassword.propTypes = {
  email: PropTypes.string.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default ResetPassword;
