import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import chooseFiles from 'choose-files';
import throttle from 'lodash.throttle';
import { message } from 'antd';
import update from 'react-addons-update';

import InfiniteScroll from 'react-infinite-scroll-component';

import Avatar from '../Avatar';
import ShipmentChatOverview from '../ShipmentChatOverview';
import MatterChatOverview from '../MatterChatOverview';
import MessageEditor from '../MessageEditor';

import { Dropdown, Menu } from 'antd';

import { formatedChatDateTime, upperCaseFirstLetter } from '../../utils';
import { UserRoleOnShipment, FileCategories } from '../../utils/constants.js';

import './index.less';

const EMPTY_MESSAGE = {
  "root": {
    "children": [
      {
        "children": [],
        "direction": "ltr",
        "format": "",
        "indent": 0,
        "type": "paragraph",
        "version": 1
      }
    ],
    "direction": "ltr",
    "format": "",
    "indent": 0,
    "type": "root",
    "version": 1
  }
};

const ChannelChat = (props) => {
  const menu = (
    <Menu
      items={
        Object.keys(FileCategories).map((k) => ({
          key: FileCategories[k].value,
          label: FileCategories[k].label,
          onClick: () => chooseFiles(uploadAttachment(FileCategories[k].value))
        }))
      }
    />
  );

  const { size, channel, currentUserId } = props;
  const { sendMessage, fetchMoreMessages } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(new Date().valueOf());
  const [inputMsg, setInputMsg] = useState(EMPTY_MESSAGE);
  const [collaboratorsMap, setCollaboratorsMap] = useState({});

  const usersMenu = (
    <Menu
      items={
        channel?.shipment.collaborators.filter(c => c.userId != currentUserId).map((c) => ({
          key: c.userId,
          label: (
            <div className='at-user-menu'>
              <div className='at-user-menu__avatar'>
                <Avatar firstName={ c.user.first_name } lastName={ c.user.last_name } email={ c.user.email } />
              </div>

              <div className='at-user-menu__info'>
                <div className='at-user-menu__info--name'>{c.user.first_name}</div>
                <div className='at-user-menu__info--email'>{c.user.email}</div>
              </div>
            </div>
          ),
          onClick: () => {
            insertAtUser(`@${upperCaseFirstLetter(c.user.first_name)}${upperCaseFirstLetter(c.user.last_name)}`, c.userId);
          }
        }))
      }
    />
  );

  const insertAtUser = (name, userId) => {
    const newInputMsg = update(inputMsg, {
      root: {
        children: {
          [inputMsg.root.children.length - 1]: {
            children: {
              $push: [
                {
                  "detail": 1,
                  "format": 0,
                  "mode": "segmented",
                  "style": "",
                  "text": name,
                  "type": "mention",
                  "version": 1,
                  "mentionId": userId
                },
                {
                  "detail": 0,
                  "format": 0,
                  "mode": "normal",
                  "style": "",
                  "text": " ",
                  "type": "text",
                  "version": 1,
                }
              ]
            }
          }
        }
      }
    });

    setUpdateTimestamp(new Date().valueOf());
    setInputMsg(newInputMsg);
  }

  const onKeyDown = (e) => {
    if (!e.ctrlKey && !e.shiftKey && (e.charCode === 13 || e.keyCode === 13)) {
      e.preventDefault();
      e.stopImmediatePropagation();

      send();
    }
  }

  const openNewTab = (path) => () => {
    window.open(`${window.location.origin}/${path}`, '_blank');
  }

  useEffect(() => {
    if (!!channel) {
      let cacheMap = {};
      channel.shipment.collaborators.forEach((c) => {
        cacheMap[c.userId] = c;
      });
      setCollaboratorsMap(cacheMap);
    }
    scrollToBottom();

    setUpdateTimestamp(new Date().valueOf());
    setInputMsg(EMPTY_MESSAGE);
  }, [channel]);

  useEffect(() => {
    const lastestMsg = channel?.messages[0];
    if (lastestMsg?.userId == currentUserId) {
      scrollToBottom();
    }
  }, [channel?.messages[0]]);

  const send = () => {
    const trimedContent = inputMsg.root.children.map((c1) => (
      c1.children.map((c2) => (
        c2.text
      )
    ))).flat().filter(c => c != null);

    if (trimedContent.length > 0) {
      sendMessage(channel.id)({
        message: inputMsg
      });
    }

    setUpdateTimestamp(new Date().valueOf());
    setInputMsg(EMPTY_MESSAGE);
  }

  const uploadAttachment = (category) => (files) => {
    if (files.length > 10) {
      message.error('You can only upload 10 files at a time.', 5);
      return;
    }

    const errors = [];
    files.forEach((f) => {
      if (f.size / 1048576 > 20) {
        errors.push(`The file's size can not be large than 20MB, "${f.name}".`);
      }

      if (f.type.includes("exe")) {
        errors.push(`Can not upload ${f.type} file, "${f.name}".`);
      }
    });

    if (errors.length > 0) {
      errors.forEach((err) => {
        message.error(err);
      });
      return;
    }

    sendMessage(channel.id)({
      message: {
        type: 'Attachment',
        files: files.map((file) => {
          const splitFileName = file.name.split('.');
          const extension = splitFileName.pop();
          const name = splitFileName.join('.');

          return {
            content: file,
            extension,
            name,
          };
        }),
        category,
      }
    });
  }

  const _readMessages = () => {
    const dom = document.getElementById('chat-content');
    dom.style.width = 'calc(100% - 1px)';
    setTimeout(() => {
      dom.style.width = '100%';
    }, 100);
    props.readMessages(currentUserId, channel.id);
  }

  const readMessages = () => {
    return throttle(_readMessages, 3000);
  }

  const scrollToBottom = () => {
    const scrollArea = document.getElementById('chat-content');
    scrollArea.scrollTo({
      top: scrollArea.scrollHeight * 1.5,
    });
  }

  return (
    <div className={`channel-chat ${size}`}>
      <div id='chat-content' className='channel-chat__chat-content' onScroll={readMessages()} onClick={readMessages()}>
        {
          (channel?.messages || []).length == 0 ? (
            <div className='channel-chat__chat-content--no-message'>No message</div>
          ) : null
        }

        <InfiniteScroll
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          dataLength={channel?.messages.length || 0}
          // loader={<div className='channel-chat__chat-content--no-message'>Loading...</div>}
          loader={null}
          hasMore={!channel?.noMoreMessages}
          scrollableTarget='chat-content'
          next={fetchMoreMessages}
          inverse={true}
        >
          {
            (channel?.messages || []).map((msg) => {
              const unreadMessagesCount = Object.values(msg.relatedUsers.Unread).length;

              return (
                <div className={`channel-chat__message ${msg.from.id == currentUserId ? 'sent' : ''}`} key={msg.id}>
                  <div className='channel-chat__message--avatar'>
                    <Avatar firstName={msg.from.first_name} lastName={msg.from.last_name} email={msg.from.email} size='middle' />
                  </div>

                  <div className='channel-chat__message--content'>
                    <div className='channel-chat__message--info'>
                      <div className='channel-chat__message--username'>
                        {msg.from.first_name}
                        <span>{ formatedChatDateTime(msg.created_at) }</span>
                      </div>
                      <div className='channel-chat__message--role'>
                        { UserRoleOnShipment[collaboratorsMap[msg.from.id]?.role]?.label }
                      </div>
                    </div>
                    {
                      msg.type == 'Text' ? (
                        <div className='channel-chat__message--body'>
                          {
                            msg.data.root.children.map((c1, i1) => {
                              return (
                                <p key={`c1-${i1}`}>
                                  {
                                    c1.children.map((c2, i2) => {
                                      if (c2.type == 'linebreak') {
                                        return '\n';
                                      }

                                      if (c2.type == 'mention') {
                                        return (
                                          c2.mentionId == currentUserId ? (
                                            <span style={{ backgroundColor: '#2C6CE4', color: '#FFF', fontWeight: 500, padding: '2px', borderRadius: '4px' }} key={`c2-${i2}`}>
                                              { c2.text }
                                            </span>
                                          ) : (
                                            <span style={{ color: '#2C6CE4' }} key={`c2-${i2}`}>
                                              { c2.text }
                                            </span>
                                          )
                                        );
                                      }

                                      return c2.text;
                                    })
                                  }
                                </p>
                              );
                            })
                          }
                        </div>
                      ) : (
                        <div className='channel-chat__message--attachment' onClick={openNewTab(msg.data.filePath)}>
                          <div className='channel-chat__message--file-category'>
                            <i className='fa-regular fa-folder-closed' />
                            { FileCategories[msg.data.fileCategory].label }
                          </div>
                          <div className='channel-chat__message--filename'>{ msg.data.fileName }</div>
                        </div>
                      )
                    }

                    {
                      msg.from.id == currentUserId ? (
                        <div className={`channel-chat__message--read-info ${ !!unreadMessagesCount ? 'unread' : ''}`}>
                          {
                            !!unreadMessagesCount ? (
                              <span>{channel.shipment.collaborators.length -1 - unreadMessagesCount}</span>
                            ) : (
                              <i className='fa-solid fa-check' />
                            )
                          }
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              );
            })
          }
        </InfiniteScroll>

        {/*
        <div className='channel-chat__message'>
          <div className='channel-chat__message--avatar'>
            <Avatar name='R' size='middle' />
          </div>

          <div className='channel-chat__message--content'>
            <div className='channel-chat__message--username'>Roy</div>
            <div className='channel-chat__message--body card'>
              <MatterChatOverview />
            </div>
          </div>
        </div>
        */}

        {/*<ShipmentChatOverview />*/}
      </div>

      <div className='channel-chat__chat-input'>
        <div className='channel-chat__chat-input--tool-bar'>
          <Dropdown
            trigger={['click']}
            placement='top'
            overlay={usersMenu}
          >
            <i className='fa-solid fa-at' />
          </Dropdown>
          {/*<i className='fa-solid fa-hashtag' />*/}
          {/*<i className='fa-regular fa-image' />*/}
          <Dropdown
            trigger={['click']}
            overlay={menu}
            placement='top'
          >
            <i className='fa-regular fa-folder-closed' />
          </Dropdown>
          {/*<i className='fa-solid fa-plus' />*/}
        </div>

        <div className='channel-chat__chat-input--input-area'>
          {/*
          <textarea onChange={(e) => setInputMsg(e.target.value)} placeholder='Reply to the message, send the Enter key' value={inputMsg} onKeyPress={onKeyDown} />
          */}
          <MessageEditor
            onChange={setInputMsg}
            value={inputMsg}
            updateTimestamp={updateTimestamp}
            onKeyDown={onKeyDown}
            mentionableUsers={channel?.shipment.collaborators.filter(c => c.userId != currentUserId)}
          />

          <div className='channel-chat__chat-input--send-btn' onClick={ send }>Send</div>
        </div>
      </div>
    </div>
  );
};

ChannelChat.propTypes = {
  size: PropTypes.string,
  channel: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  readMessages: PropTypes.func.isRequired,
  fetchMoreMessages: PropTypes.func.isRequired
};

export default ChannelChat;
